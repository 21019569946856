import * as THREE from 'three'
import {
  componentPortalList,
  inspectionPortalList,
  inspectionShipViewList,
  rolodexComponentEntry,
  rolodexInspectionEntry,
  rolodexSiteEntry,
  rolodexUnitEntry,
  sitePortalList,
  stat,
  unitPortalList,
  unitShipViewList,
} from 'api/interfaces'
import React, { Suspense, useEffect, useState } from 'react'
import {
  useRolodexEntry,
  useRolodexEntryId,
  useRolodexEntrySite,
  useRolodexImage,
} from '../api/useRolodex'
import { Texture } from 'three'
import { useLocation } from 'react-router-dom'
import { IterateNested } from '../api/helpers'

import Layout from './Layout'
import LoadingContent from './LoadingContent'
import { inspect } from 'util'

function GetImageLoading(inspectionId: string, layerName: string) {
  const imageLoading = useRolodexImage(
    `shipview-${inspectionId}`,
    layerName.includes('rl') ? `${layerName}.png` : `shipview_${layerName}_layer`
  )
  let texture = new THREE.TextureLoader().load(imageLoading)
  return texture
}

export default function LoadData() {
  const location = useLocation()
  const unit = location.pathname.slice(1)

  const [siteData, setSiteData] = React.useState<{ entry: rolodexSiteEntry }[] | null>(
    null
  )
  const [unitData, setUnitData] = React.useState<{ entry: rolodexUnitEntry }[] | null>(
    null
  )
  const [componentData, setComponentData] = React.useState<
    { entry: rolodexComponentEntry }[] | null
  >(null)
  const [filteredComponentData, setFilteredComponentData] = React.useState<
    { entry: rolodexComponentEntry }[] | null
  >(null)
  const [inspectionData, setInspectionData] = React.useState<any>(null)
  const [filteredInspectionData, setFilteredInspectionData] = React.useState<any>(null)
  const [generateInspectionLayers, setGenerateInspectionLayers] = React.useState<
    Map<string, Map<string, boolean>>
  >(new Map<string, Map<string, boolean>>())
  const [inspectionTextures, setInspectionTextures] = React.useState<
    Map<string, Map<string, Texture>>
  >(new Map<string, Map<string, Texture>>())
  const [missingData, setMissingData] = React.useState(false)
  const [missingSiteData, setMissingSiteData] = React.useState<string[]>([])
  const [missingUnitData, setMissingUnitData] = React.useState<string[]>([])
  const [missingComponentData, setMissingComponentData] = React.useState<string[]>([])
  const [missingInspectionData, setMissingInspectionData] = React.useState<string[]>([])

  const stats: Map<string, Map<string, stat>> = new Map<string, Map<string, stat>>()

  useRolodexEntry('component', 'unit', 'unit', 'portal.slug', unit).then((data) =>
    setComponentData(data)
  )
  useRolodexEntry('inspection', 'unit', 'unit', 'portal.slug', unit).then((data) => 
    setInspectionData(data)
  )

  useRolodexEntryId(unit).then((data) => setUnitData(data))
  useRolodexEntrySite(unit).then((data) => setSiteData(data))

  const setInspectionLayers = (inspectionId: string, layerName: string) => {
    if (!generateInspectionLayers.has(inspectionId)) {
      generateInspectionLayers.set(
        inspectionId,
        new Map<string, boolean>([[layerName, true]])
      )
    } else {
      // @ts-ignore
      if (!generateInspectionLayers.get(inspectionId).has(layerName)) {
        // @ts-ignore
        generateInspectionLayers.get(inspectionId).set(layerName, true)
      } else {
        // @ts-ignore
        generateInspectionLayers
          .get(inspectionId)
          // @ts-ignore
          .set(layerName, !generateInspectionLayers.get(inspectionId).get(layerName))
      }
    }
  }

  const loadInspectionLayers = (layersMap: Map<string, Map<string, Texture>>) => {
    setInspectionTextures(layersMap)
  }

  const populateStats = (missingData: boolean) => {
    if (!missingData && filteredInspectionData !== null) {
      for (let i = 0; i < filteredInspectionData.length; i++) {
        const curInspectionStats = filteredInspectionData[i].entry.data.shipview.stats
        let curStats = new Map<string, stat>()
        for (let j = 0; j < curInspectionStats.length; j++) {
          curStats.set(curInspectionStats[j].name, curInspectionStats[j])
        }
        stats.set(filteredInspectionData[i].entry.id, curInspectionStats)
      }
    }
  }

  const populateHeatMapVisibility = (missingData: boolean) => {
    if (!missingData && filteredInspectionData !== null) {
      for (let i = 0; i < filteredInspectionData.length; i++) {
        let curMap = new Map<string, boolean>()
        const curTextures =
          filteredInspectionData[i].entry.data.shipview.inspection_display.textures
        for (let j = 0; j < curTextures.length; j++) {
          curMap.set(curTextures[j].name, curTextures[j].visibility)
        }
        generateInspectionLayers.set(filteredInspectionData[i].entry.id, curMap)
      }
    }
  }

  const validateData = () => {
    let badData = false
    let missingSiteData = []
    let missingUnitData = []
    let missingComponentData = []
    let missingInspectionData = []

    if (siteData !== null) {
      const site = siteData[0].entry.data
      if (site.hasOwnProperty('portal') === false) {
        badData = true
        missingSiteData.push(siteData[0].entry.id + '_portal')
      } else {
        for (let i = 0; i < sitePortalList.length; i++) {
          if (site.portal.hasOwnProperty(sitePortalList[i]) === false) {
            badData = true
            missingSiteData.push(siteData[0].entry.id + '_portal_' + sitePortalList[i])
          }
        }
      }
      setMissingData(badData)
      setMissingSiteData(missingSiteData)
    }

    if (unitData !== null) {
      if (unitData.length > 0) {
        //this whole code block is checking if everything in the interface is present actually
        const unit = unitData[0].entry.data
        if (unit.hasOwnProperty('shipview') === false) {
          badData = true
          missingUnitData.push(unitData[0].entry.id + '_shipview')
        } else if (unit.hasOwnProperty('portal') === false) {
          badData = true
          missingUnitData.push(unitData[0].entry.id + '_portal')
        } else {
          //check if shipview has all properties found in the unitShipViewList
          for (let i = 0; i < unitShipViewList.length; i++) {
            if (unit.shipview.hasOwnProperty(unitShipViewList[i]) === false) {
              badData = true
              missingUnitData.push(
                unitData[0].entry.id + '_shipview_' + unitShipViewList[i]
              )
            }
          }
          for (let i = 0; i < unitPortalList.length; i++) {
            if (unit.portal.hasOwnProperty(unitPortalList[i]) === false) {
              badData = true
              missingUnitData.push(unitData[0].entry.id + '_portal_' + unitPortalList[i])
            }
          }
        }
        setMissingData(badData)
        setMissingUnitData(missingUnitData)
      } else {
        setMissingData(true)
        setMissingUnitData([`There is no unit named ${unit}`])
      }
    }

    if (filteredComponentData !== null) {
      for (let i = 0; i < filteredComponentData.length; i++) {
        if (filteredComponentData[i].entry.data.hasOwnProperty('portal') === false) {
          badData = true
          missingComponentData.push(filteredComponentData[i].entry.id + '_portal')
        } else {
          for (let i = 0; i < componentPortalList.length; i++) {
            if (
              !filteredComponentData[i].entry.data.portal.hasOwnProperty(componentPortalList[i])
            ) {
              badData = true
              missingComponentData.push(
                filteredComponentData[i].entry.id + '_portal_' + componentPortalList[i]
              )
            }
          }
        }
        setMissingData(badData)
        setMissingComponentData(missingComponentData)
      }
    }

    if (filteredInspectionData !== null) {
      for (let i = 0; i < filteredInspectionData.length; i++) {
        if (filteredInspectionData[i].entry.data.hasOwnProperty('shipview') === false) {
          badData = true
          missingInspectionData.push(filteredInspectionData[i].entry.id + '_shipview')
        } else if (filteredInspectionData[i].entry.data.hasOwnProperty('portal') === false) {
          badData = true
          missingInspectionData.push(filteredInspectionData[i].entry.id + '_portal')
        } else {
          //check if shipview has all properties found in the inspectionShipViewList
          let missingParams = IterateNested(
            filteredInspectionData[i].entry.data.shipview,
            inspectionShipViewList
          )
          for (let j = 0; j < missingParams.length; j++) {
            badData = true
            missingInspectionData.push(
              filteredInspectionData[i].entry.id + '_shipview_' + missingParams[j]
            )
          }
          for (let j = 0; j < inspectionPortalList.length; j++) {
            if (
              !filteredInspectionData[i].entry.data.portal.hasOwnProperty(inspectionPortalList[j])
            ) {
              badData = true
              missingInspectionData.push(
                filteredInspectionData[i].entry.id + '_portal_' + inspectionPortalList[j]
              )
            }
          }
        }
        setMissingData(badData)
        setMissingInspectionData(missingInspectionData)
        populateStats(badData)
        populateHeatMapVisibility(badData)
      }
    }
  }

 // Update the state of the filtered data only when inspectionData changes
  useEffect(() => {
    // only include certain components for ethridge demo
    if (unitData != null && unitData[0].entry.id === "sentinel-class-cutter-306782") {
      const componentsToInclude = ['60a61-278-118-aab0f4', '60a61-126-112-9140c5', '30a33-012-9-9a3fbd']
      if (inspectionData !== null) {
        const newInspectionData = inspectionData.filter((inspection: { entry: { tags: { component: string } } }) => componentsToInclude.includes(inspection.entry.tags.component))
        setFilteredInspectionData(newInspectionData)
      }
      if (componentData !== null) {
        const newComponentData = componentData.filter(component => componentsToInclude.includes(component.entry.id))
        setFilteredComponentData(newComponentData)
      }
    } else {
      setFilteredInspectionData(inspectionData)
      setFilteredComponentData(componentData)
    }
  }, [inspectionData, componentData])


  useEffect(() => {
    validateData()
  }, [unitData, filteredInspectionData, filteredComponentData, siteData])

  if (
    siteData === null ||
    unitData === null ||
    filteredComponentData === null ||
    filteredInspectionData === null
  ) {
    return <LoadingContent />
  } else {
    return (
      <>
        <LoadInspectionsOverlays
          inspectionData={filteredInspectionData}
          setInspectionLayers={loadInspectionLayers}
        />
        <Layout
          unitData={unitData}
          siteData={siteData}
          componentData={filteredComponentData}
          inspectionData={filteredInspectionData}
          missingData={missingData}
          missingUnitData={missingUnitData}
          missingSiteData={missingSiteData}
          missingComponentData={missingComponentData}
          missingInspectionData={missingInspectionData}
          inspectionLayers={generateInspectionLayers}
          inspectionTextures={inspectionTextures}
          stats={stats}
        />
      </>
    )
  }
}

interface LoadLayersProps {
  inspectionData: { entry: rolodexInspectionEntry }[]
  setInspectionLayers: (layersMap: Map<string, Map<string, Texture>>) => void
}

export function LoadInspectionsOverlays(props: LoadLayersProps) {
  const [heatMaps, setHeatMaps] = useState<Map<string, Map<string, Texture>>>(
    new Map<string, Map<string, Texture>>()
  )

  // console.log('this is running how many times?')
  for (let i = 0; i < props.inspectionData.length; i++) {
    const textureData =
      props.inspectionData[i].entry.data.shipview.inspection_display.textures
    let inspectionMap = new Map<string, Texture>()
    for (let j = 0; j < textureData.length; j++) {
      inspectionMap.set(
        textureData[j].name,
        GetImageLoading(props.inspectionData[i].entry.id, textureData[j].name)
      )

      heatMaps.set(props.inspectionData[i].entry.id, inspectionMap)
    }
  }
  props.setInspectionLayers(heatMaps)

  return <></>
}

import './App.css'
import { useState } from 'react'
import LoggedInContent from './pages/LoggedInContent'
import { SWRConfig } from 'swr'
import {
  GeckoThemeProvider,
  GeckoTheme,
  ThemeType,
  AuthProvider,
  MessageBanner,
} from 'gecko-ui'
import { Auth0ClientId } from './api/helpers'

const App = () => {
  const [showBanner, setShowBanner] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  return (
    <GeckoThemeProvider theme={GeckoTheme as ThemeType}>
      <AuthProvider clientId={Auth0ClientId()}>
        <SWRConfig
          value={{
            onSuccess: (data, key, config) => {
              setShowBanner(false)
              setErrorMessage(undefined)
            },
            onError: (error, key) => {
              setShowBanner(true)
              setErrorMessage(error.message)
            },
            shouldRetryOnError: false,
            errorRetryCount: 1,
          }}>
          <MessageBanner
            showBanner={showBanner}
            setShowBanner={setShowBanner}
            contents={errorMessage}
          />
          <LoggedInContent />
        </SWRConfig>
      </AuthProvider>
    </GeckoThemeProvider>
  )
}

export default App

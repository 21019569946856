//TODO: add the exact interfaces for the schema

interface siteShipView {}

//todo: standardize silo datafile (prolly just a json) ill ask for suggestions
interface unitShipView {
  unit_name_3d_pos: [number, number, number]
  unit_name_3d_rot: [number, number, number]
  unit_name_3d_scale: number
  camera_default_pos: [number, number, number]
  camera_default_rot: [number, number, number, number]
  asset_type: string
  models: Array<model>
}

export interface model {
  name: string
  scale: number
  position: [number, number, number]
  rotation: [number, number, number]
}

interface componentShipView {}

export interface inspectionTextureParameters {
  tmin_colorbar_threshold?: number
  nominal_colorbar_threshold?: number
  name: string
  visibility: boolean
  button_text: string
}

interface inspectionShipView {
  operational_availability: string
  asset_type: string
  billboard: {
    position: [number, number, number]
    line_start: [number, number, number]
    line_end: [number, number, number]
    stats_display: Array<string>
  }
  inspection_display: {
    geometry_type: string
    textures: Array<inspectionTextureParameters>
    mesh: string
  }
  destroyer_hull_demo: boolean

  text_metadata:
    | [titleWidget | subtitleWidget | operationalStatusWidget | primaryButtonWidget]
    | []
    | [titleWidget]
    | [subtitleWidget]
    | [operationalStatusWidget]
    | [primaryButtonWidget]
  stats: [stat] | []
}

export interface layer {
  visibility: boolean
  name: string
  button_text: string
  tmin_colorbar_threshold?: number
  nominal_colorbar_threshold?: number
}

export interface operationalStatusWidget {
  widget_type: 'Operational Status'
  value: string
}

export interface primaryButtonWidget {
  widget_type: 'Primary Button'
  value: string
  key: string
}

export interface stat {
  name: string
  data_type: string
  value: string
  prefix: string
  suffix: string
  description: string
}
export interface titleWidget {
  widget_type: 'Title'
  value: string
}

export interface subtitleWidget {
  widget_type: 'Subtitle'
  key: string
  value: string
}

export const sitePortalList = ['name']

export const unitPortalList = ['name']

export const unitShipViewList = [
  'unit_name_3d_pos',
  'unit_name_3d_rot',
  'unit_name_3d_scale',
  'asset_type',
  'camera_default_pos',
  'camera_default_rot',
  'models',
]

export const componentPortalList = ['name']

export const inspectionPortalList = ['id', 'date']

export const inspectionShipViewList = [
  // 'billboard',
  // 'position',
  // 'line_start',
  // 'line_end',
  // 'stats_display',
  'stats',
  'inspection_display',
  'geometry_type',
  'textures',
  'text_metadata',
  'mesh',
]

export interface rolodexOrganizationEntry {
  data: { portal: any }
  id: string
  tags: any
}

export interface primaryButtonWidget {
  widget_type: 'Primary Button'
  value: string
  key: string
}

export interface rolodexSiteEntry {
  data: { portal: any }
  id: string
  tags: any
  type: string
}

export interface rolodexUnitEntry {
  data: {
    portal: any
    shipview: unitShipView
    service_on_time?: string
    asset_health?: string
  }
  id: string
  tags: any
  type: string
}

export interface rolodexComponentEntry {
  data: { portal: any }
  id: string
  tags: any
  type: string
}

export interface rolodexInspectionEntry {
  data: { portal: any; shipview: inspectionShipView }
  id: string
  tags: any
  type: string
}

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: Yi Tsung Lee (https://sketchfab.com/WTigerTw)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/11-low-poly-us-navy-ddg-51-uss-arleigh-burke-17be09c31c6047e4a5969b68c29eba03
title: 1:1 Low poly US NAVY DDG-51 USS Arleigh Burke.
*/

import { useGLTF } from '@react-three/drei'
import { useGraph } from '@react-three/fiber';
import { MeshStandardMaterial } from 'three'
import * as THREE from 'three'

export default function Destroyer(props: any) {
  const gltf = useGLTF('/destroyer_inspection.glb')
  const { nodes, materials } = useGraph(gltf.scene);

  const mat = new MeshStandardMaterial()
  mat.opacity = 0.3
  mat.transparent = true
  mat.color = new THREE.Color('#FFFF00')

  return (
    <group {...props} dispose={null} rotation={[0, 0, 0]}>
      <group rotation={nodes.Object_4.rotation} position={nodes.Object_4.position}>
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.Object_4 as THREE.Mesh).geometry}
          material={materials.CIWS}
        />
      </group>
      <group rotation={nodes.Object_6.rotation} position={nodes.Object_6.position}>
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.Object_6 as THREE.Mesh).geometry}
          material={materials.DDG112}
        />
      </group>
      <group rotation={nodes.Object_8.rotation} position={nodes.Object_8.position}>
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.Object_8 as THREE.Mesh).geometry}
          material={materials.mk38}
        />
      </group>
      <group rotation={nodes.Object_10.rotation} position={nodes.Object_10.position}>
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.Object_10 as THREE.Mesh).geometry}
          material={materials.DDG112}
        />
      </group>
      <group rotation={nodes.Object_12.rotation} position={nodes.Object_12.position}>
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.Object_12 as THREE.Mesh).geometry}
          material={materials['RHIB.001']}
        />
      </group>
      <group rotation={nodes.Object_14.rotation} position={nodes.Object_14.position}>
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.Object_14 as THREE.Mesh).geometry}
          material={materials.spg62}
        />
      </group>
      {/*<group rotation={nodes.flight_deck.rotation} position={nodes.flight_deck.position}>*/}
      {/*  <mesh*/}
      {/*    castShadow*/}
      {/*    receiveShadow*/}
      {/*    geometry={(nodes.flight_deck as THREE.Mesh).geometry}*/}
      {/*    material={mat}*/}
      {/*  />*/}
      {/*</group>*/}
    </group>
  )
}

useGLTF.preload('/destroyer_inspection.glb')

import { primaryButtonWidget } from 'api/interfaces'
import React from 'react'
import styled from 'styled-components'

const GenerateMeshButton = styled.button`
  pointer: cursor;
  font-size: 1.3rem;
  background-color: transparent;
  border: 0.2rem solid white;
  color: white;
  padding: 0.25rem 1rem;
  margin-top: 2rem;
  border-radius: 0.9rem;
  align-self: center;
  &:hover {
    background-color: white;
    color: rgb(11, 33, 45);
  }
  width: 100%;
  text-align: center;
`

interface PrimaryButtonWidgetProps {
  primaryButton: primaryButtonWidget
}

function PrimaryButtonWidget(props: PrimaryButtonWidgetProps) {
  const openLinkInNewTab = (url: string | undefined) => {
    if (url == null) {
      return
    }
    const newTab = window.open(url, '_blank', 'noopener,noreferrer')
    if (newTab) newTab.opener = null
  }

  return (
    <GenerateMeshButton
      onClick={() => {
        openLinkInNewTab(props.primaryButton?.key)
      }}>
      {props.primaryButton?.value}
    </GenerateMeshButton>
  )
}

export default React.memo(PrimaryButtonWidget)

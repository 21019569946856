import { Canvas } from '@react-three/fiber'
import Experience from 'components/threefiber/Experience'
import { Loader, OrbitControls } from '@react-three/drei'
import Dossier from 'components/other/Dossier/Dossier'
import React, { Suspense, useCallback, useEffect } from 'react'
import { ReactComponent as GeckoLogo } from 'assets/gecko-logo.svg'
import TopBar from 'components/other/TopBar/TopBar'
import {
  rolodexComponentEntry,
  rolodexSiteEntry,
  rolodexUnitEntry,
  stat,
  rolodexInspectionEntry,
} from 'api/interfaces'
import LoadingContent from './LoadingContent'
import styled from 'styled-components'
import Stats from 'components/other/Stats/Stats'
import { Texture } from 'three'
import Staging from 'components/threefiber/Staging'
import UnitSelector from 'components/other/UnitSelector/UnitSelector'

const StyledCanvas = styled(Canvas)`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    0deg,
    hsl(0deg 0% 20%) 0%,
    hsl(344deg 0% 19%) 25%,
    hsl(344deg 0% 18%) 42%,
    hsl(344deg 0% 16%) 54%,
    hsl(344deg 0% 15%) 63%,
    hsl(344deg 0% 14%) 70%,
    hsl(344deg 0% 13%) 76%,
    hsl(344deg 0% 12%) 81%,
    hsl(344deg 0% 10%) 86%,
    hsl(344deg 0% 9%) 91%,
    hsl(344deg 0% 8%) 95%,
    hsl(0deg 0% 7%) 100%
  );
`

const InfoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const LogoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`

const LogoStyled = styled.div`
  margin-bottom: 1rem;
  width: 8rem;
  height: 8rem;
`


export default function Landing() {


  return (
    <ContainerDiv>
        <TopBar />
        <InfoContainer>
          <UnitSelector />
          <LogoBox>
            <LogoStyled>
              <GeckoLogo />
            </LogoStyled>
          </LogoBox>
          <Loader />
        </InfoContainer>
      </ContainerDiv>
  )
}

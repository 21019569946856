import { stat } from 'api/interfaces'
import styled from 'styled-components'
import { commas } from 'util/commas'
import React from 'react'

const GridValue = styled.p`
  text-align: center;
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.05rem;
`

const GridValueRed = styled.p`
  text-align: center;
  color: #bd655f;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.05rem;
`

const GridLabel = styled.p`
  text-align: center;
  color: grey;
  font-size: 0.8rem;
  font-weight: 500;
`
const StatsGridOne = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(autofill, 2, 1fr);
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  grid-column-gap: 0.5rem;
`
const StatsGridTwo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(autofill, 2, 1fr);
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  grid-column-gap: 0.5rem;
`
const StatsGridThree = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(autofill, 2, 1fr);
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  grid-column-gap: 0.5rem;
`
const StatsGridItem = styled.div`
  display: flex;
  justify-content: center;
`

interface StatsStatsWidgetProps {
  stats: stat[]
  // setStats: (stats: stat[]) => void
}

function StatsStatsWidget(props: StatsStatsWidgetProps) {
  const renderStats = () => {
    let stat1
    let stat2
    let stat3
    switch (props.stats.length) {
      case 0:
        return <></>
      case 1:
        stat1 = props.stats.pop()
        return (
          <StatsGridOne>
            <StatsGridItem>
              <StatsGridValue
                value={stat1?.value}
                prefix={stat1?.prefix}
                suffix={stat1?.suffix}
                name={stat1?.name}
              />
            </StatsGridItem>
            <StatsGridItem>
              <GridLabel>{stat1?.description}</GridLabel>
            </StatsGridItem>
          </StatsGridOne>
        )
      case 2:
        stat1 = props.stats.pop()
        stat2 = props.stats.pop()
        return (
          <StatsGridTwo>
            <StatsGridItem>
              <StatsGridValue
                value={stat1?.value}
                prefix={stat1?.prefix}
                suffix={stat1?.suffix}
                name={stat1?.name}
              />
            </StatsGridItem>
            <StatsGridItem>
              <StatsGridValue
                value={stat2?.value}
                prefix={stat2?.prefix}
                suffix={stat2?.suffix}
                name={stat2?.name}
              />
            </StatsGridItem>
            <StatsGridItem>
              <GridLabel>{stat1?.description}</GridLabel>
            </StatsGridItem>
            <StatsGridItem>
              <GridLabel> {stat2?.description}</GridLabel>
            </StatsGridItem>
          </StatsGridTwo>
        )
      case 3:
        stat1 = props.stats.pop()
        stat2 = props.stats.pop()
        stat3 = props.stats.pop()
        return (
          <StatsGridThree>
            <StatsGridItem>
              <StatsGridValue
                value={stat1?.value}
                prefix={stat1?.prefix}
                suffix={stat1?.suffix}
                name={stat1?.name}
              />
            </StatsGridItem>
            <StatsGridItem>
              <StatsGridValue
                value={stat2?.value}
                prefix={stat2?.prefix}
                suffix={stat2?.suffix}
                name={stat2?.name}
              />
            </StatsGridItem>
            <StatsGridItem>
              <StatsGridValue
                value={stat3?.value}
                prefix={stat3?.prefix}
                suffix={stat3?.suffix}
                name={stat3?.name}
              />
            </StatsGridItem>
            <StatsGridItem>
              <GridLabel>{stat1?.description} </GridLabel>
            </StatsGridItem>
            <StatsGridItem>
              <GridLabel>{stat2?.description}</GridLabel>
            </StatsGridItem>
            <StatsGridItem>
              <GridLabel> {stat3?.description}</GridLabel>
            </StatsGridItem>
          </StatsGridThree>
        )
      default:
        stat1 = props.stats.pop()
        stat2 = props.stats.pop()
        stat3 = props.stats.pop()
        return (
          <StatsGridThree>
            <StatsGridItem>
              <StatsGridValue
                value={stat1?.value}
                prefix={stat1?.prefix}
                suffix={stat1?.suffix}
                name={stat1?.name}
              />
            </StatsGridItem>
            <StatsGridItem>
              <StatsGridValue
                value={stat2?.value}
                prefix={stat2?.prefix}
                suffix={stat2?.suffix}
                name={stat2?.name}
              />
            </StatsGridItem>
            <StatsGridItem>
              <StatsGridValue
                value={stat3?.value}
                prefix={stat3?.prefix}
                suffix={stat3?.suffix}
                name={stat3?.name}
              />
            </StatsGridItem>
            <StatsGridItem>
              <GridLabel>{stat1?.description} </GridLabel>
            </StatsGridItem>
            <StatsGridItem>
              <GridLabel>{stat2?.description}</GridLabel>
            </StatsGridItem>
            <StatsGridItem>
              <GridLabel> {stat3?.description}</GridLabel>
            </StatsGridItem>
          </StatsGridThree>
        )
    }
  }
  return renderStats()
}

interface valueProps {
  value: string | undefined
  prefix: string | undefined
  suffix: string | undefined
  name: string | undefined
}

function StatsGridValue(props: valueProps) {
  if (props.name === 'repair_area') {
    return (
      <GridValueRed>{commas(props?.value, props?.prefix, props?.suffix)}</GridValueRed>
    )
  } else {
    return <GridValue>{commas(props?.value, props?.prefix, props?.suffix)}</GridValue>
  }
}

export default React.memo(StatsStatsWidget)

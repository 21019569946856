import { ColorBarTitle, TopTick, BottomTick, WastageColorBar } from './styles'

interface WastageColorbarProps {}

export default function WastageColorBarItem(props: WastageColorbarProps) {
  return (
    <WastageColorBar>
      <ColorBarTitle> Excess Wastage</ColorBarTitle>
      <TopTick />
      <BottomTick />
    </WastageColorBar>
  )
}

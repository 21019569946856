export default function fragmentShader(numTextures: number, opacity: number) {
  // if (numTextures === 0) {
  //   return `
  //   uniform int num_textures;
  //   void main() {
  //       //gl_FragColor = vec4(193, 198,199, 0.1);
  //       gl_FragColor = vec4(255, 0, 0, 0.4);
  //       }
  //     `

  //   return `
  //
  //   #define numTextures ${numTextures}
  // varying vec2 v_uv;
  //
  //
  // uniform sampler2D u_textures[numTextures];
  // uniform vec2 u_uv_scale;
  //
  // void main() {
  //
  //
  //     vec2 scaled_uv = v_uv / u_uv_scale;
  //     vec4 texture = texture2D(u_textures, scaled_uv);
  //     gl_FragColor = vec4(texture.x, texture.y,texture.z, texture.a);
  //     }
  //   `

  let texture_concat = ''
  for (var i = 0; i < numTextures; i++) {
    texture_concat += `
    
    if(${i} == num_textures){
      return color;
    }
      
    c = texture2D(u_textures[${i}], uv);
      if (${i} == 0) {
          color = c;
      }
      if(c.a == 1.0){
          color = c;
      }else if(c == color){
      
      }else{
        color = vec4((c.r*c.a +color.r*(1.0-c.a)), (c.g*c.a +color.g*(1.0-c.a)) , (c.b*c.a +color.b*(1.0-c.a)),(c.a + color.a));
      }
    `
  }

  return (
    `
  #define numTextures ${numTextures}
  #define op ${opacity}
  #define rota 0.7
  varying vec2 v_uv;


uniform sampler2D u_textures[numTextures];
uniform vec2 u_uv_scale;
uniform float rotation;
uniform int num_textures;
uniform bool flip_uv_x_axis;
uniform bool flip_uv_y_axis;

vec4 getSampleFromArray(sampler2D textures[numTextures], vec2 uv) {
    vec4 color = vec4(0,0,0,0);
    vec4 c = vec4(0,0,0,0);
    
` +
    texture_concat +
    `
 
    return color;
}

void main() {
    vec2 scaled_uv = v_uv / u_uv_scale;
    if(flip_uv_x_axis){
      scaled_uv = vec2(scaled_uv.s, 1.0 - scaled_uv.t);
    }
    if(flip_uv_y_axis){
      scaled_uv = vec2(1.0 - scaled_uv.s, scaled_uv.t);
    }
    if(scaled_uv.s -rotation < 0.0){
      scaled_uv = vec2(rotation - scaled_uv.s, scaled_uv.t);
    }else{
      scaled_uv = vec2(scaled_uv.s - rotation, scaled_uv.t);
    }
    
    vec4 texture = getSampleFromArray(u_textures, scaled_uv);
    gl_FragColor = vec4(texture.x, texture.y, texture.z, texture.a * op);
    if(num_textures == 0){
      gl_FragColor = vec4(193, 198,199, 0.1);
    }
    
//    vec4 texture = texture2D(u_textures, scaled_uv);
//    gl_FragColor = vec4(texture.x, texture.y, texture.z, texture.a);
}
  `
  )
}

import { animated, useSpring } from '@react-spring/web'
import styled from 'styled-components'
import ColorbarSelector from './components/colorbarComponents/ColorbarSelector'
import { layer } from 'api/interfaces'
import React from 'react'

export const ColorbarContainer = styled(animated.div)`
  position: absolute;
  height: 40vh;
  background-color: rgba(0, 0, 0, 0);
  width: 5%;
  top: 30vh;
  z-index: 1; // Ensure it overlays other elements
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

interface ColorbarProps {
  layers: layer[]
  inspectionId: string
  inspectionLayers: Map<string, Map<string, boolean>>
  inspectionClicked: boolean
}

export default function Colorbar(props: ColorbarProps) {
  const { right }: any = useSpring({
    from: { right: '0' },
    to: { right: props.inspectionClicked ? '30rem' : '0' },
  })

  const checkLayer = (layer: layer | undefined) => {
    if (!layer) {
      return false
    } else {
      const layerInMap = props.inspectionLayers.get(props.inspectionId)?.get(layer.name)
      if (layerInMap === true) {
        return true
      } else {
        return false
      }
    }
  }

  const sortLayers = (layers: layer[]) => {
    const sortOrder = ['repairs', 'heatmap', 'wastage']

    // if any of the layers contain "rl" in the name and checkLayer is true
    // then return the layer with "rl" in the name only

    layers.forEach((layer) => {
      if (layer.name.includes('rl') && checkLayer(layer)) {
        return [layer]
      }
    })

    const sortedLayers = layers.sort((a, b) => {
      const aIndex = sortOrder.indexOf(a.name)
      const bIndex = sortOrder.indexOf(b.name)

      if (aIndex === -1 && bIndex === -1) {
        return 0
      }
      if (aIndex === -1) {
        return 1
      }
      if (bIndex === -1) {
        return -1
      }
      return aIndex - bIndex
    })

    return sortedLayers
  }

  return (
    <ColorbarContainer style={{ right }}>
      {sortLayers(props.layers).map((layer, i) => {
        if (checkLayer(layer)) {
          return (
            <ColorbarSelector
              key={layer ? layer.name : i}
              layerName={layer ? layer.name : ''}
              layer={layer}
            />
          )
        } else {
          return <React.Fragment key={i} />
        }
      })}
    </ColorbarContainer>
  )
}

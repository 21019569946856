/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei'
import { useGraph } from '@react-three/fiber'

export default function DryDock(props: any) {
  const gltf = useGLTF('/drydock.glb')
  const { nodes, materials } = useGraph(gltf.scene)

  return (
    <group {...props} dispose={null} scale={35}>
      <group position={[36.01, 33.52, -18.22]} rotation={[0, -1.57, 0]} scale={0.07}>
        <group position={[11.09, 147.19, 84.56]}>
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh003 as THREE.Mesh).geometry}
            material={materials.crane3}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh003_1 as THREE.Mesh).geometry}
            material={materials.Default}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh003_2 as THREE.Mesh).geometry}
            material={materials.Metal}
          />
        </group>
        <group position={[-2, -40.1, 122.51]}>
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh004 as THREE.Mesh).geometry}
            material={materials.Crane5}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh004_1 as THREE.Mesh).geometry}
            material={materials.Roof3}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh004_2 as THREE.Mesh).geometry}
            material={materials.Roof2}
          />
        </group>
        <group position={[-0.09, -19.13, -7.9]}>
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh001 as THREE.Mesh).geometry}
            material={materials.Cabin1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh001_1 as THREE.Mesh).geometry}
            material={materials.glass}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh001_2 as THREE.Mesh).geometry}
            material={materials.crane3}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh001_3 as THREE.Mesh).geometry}
            material={materials.Metal}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh001_4 as THREE.Mesh).geometry}
            material={materials.Crane1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh001_5 as THREE.Mesh).geometry}
            material={materials.Crane2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh001_6 as THREE.Mesh).geometry}
            material={materials.Crane9}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh001_7 as THREE.Mesh).geometry}
            material={materials.crane6}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh001_8 as THREE.Mesh).geometry}
            material={materials.Roof}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh001_9 as THREE.Mesh).geometry}
            material={materials.Roof3}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh001_10 as THREE.Mesh).geometry}
            material={materials.Crane4}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh001_11 as THREE.Mesh).geometry}
            material={materials.Default}
          />
        </group>
        <group position={[-45.83, -68.37, -38.57]}>
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh005 as THREE.Mesh).geometry}
            material={materials['NMetal.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh005_1 as THREE.Mesh).geometry}
            material={materials.Brass}
          />
        </group>
        <group position={[45.83, -68.37, -38.57]}>
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh006 as THREE.Mesh).geometry}
            material={materials['NMetal.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh006_1 as THREE.Mesh).geometry}
            material={materials.Brass}
          />
        </group>
        <group position={[-45.83, -68.37, -38.57]}>
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh002 as THREE.Mesh).geometry}
            material={materials.crane3}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh002_1 as THREE.Mesh).geometry}
            material={materials.NMetal}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh002_2 as THREE.Mesh).geometry}
            material={materials.crane3A}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh002_3 as THREE.Mesh).geometry}
            material={materials.MMetal}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh002_4 as THREE.Mesh).geometry}
            material={materials['NMetal.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Mesh002_5 as THREE.Mesh).geometry}
            material={materials.Reserv}
          />
        </group>
      </group>
      <group position={[-43.7, 40.19, 2.32]} scale={0.03}>
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.Cabin21 as THREE.Mesh).geometry}
          material={materials.Crane3yellow}
          position={[-278.22, -276.46, 1.43]}>
          <group position={[-31.55, 48.02, -0.39]}>
            <mesh
              castShadow
              receiveShadow
              geometry={(nodes.Mesh008 as THREE.Mesh).geometry}
              material={materials.Crane3yellow}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={(nodes.Mesh008_1 as THREE.Mesh).geometry}
              material={materials.Cglass}
            />
          </group>
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.CrainM12 as THREE.Mesh).geometry}
            material={materials.Crane3yellow}
            position={[106.81, 6.35, -0.18]}
            scale={0.81}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Cyl21 as THREE.Mesh).geometry}
            material={materials.Crane3yellow}
            position={[668.02, 937.27, -2.38]}
            scale={0.81}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Cyl22 as THREE.Mesh).geometry}
            material={materials.Crane3yellow}
            position={[626.27, 951.42, -1.96]}
            scale={0.81}
          />
          <group position={[100.55, 58.01, -82.33]} scale={0.81}>
            <mesh
              castShadow
              receiveShadow
              geometry={(nodes.Mesh012 as THREE.Mesh).geometry}
              material={materials.Crane3yellow}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={(nodes.Mesh012_1 as THREE.Mesh).geometry}
              material={materials.Cglass}
            />
          </group>
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Hook14 as THREE.Mesh).geometry}
            material={materials.Crane3yellow}
            position={[645.06, 636.34, -2.05]}
            scale={0.81}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Part10 as THREE.Mesh).geometry}
            material={materials.Crane3yellow}
            position={[212.67, 509.38, -0.4]}
            scale={0.81}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Wiper10 as THREE.Mesh).geometry}
            material={materials.Crane3yellow}
            position={[141.65, 69.43, -57.51]}
            scale={0.81}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Wire10 as THREE.Mesh).geometry}
            material={materials.Crane3yellow}
            position={[462.78, 737.78, 8.05]}
            scale={0.81}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.Tower10 as THREE.Mesh).geometry}
          material={materials.Crane3yellow}
          position={[-278.28, -564.68, 1.35]}
        />
      </group>
      {/* <group position={[75.45, 40.19, 11.56]} rotation={[0, -1.57, 0]} scale={0.03}>
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.Cabin23 as THREE.Mesh).geometry}
          material={materials.Crane3yellow}
          position={[-278.22, -276.46, 1.43]}>
          <group position={[-31.55, 48.02, -0.39]}>
            <mesh
              castShadow
              receiveShadow
              geometry={(nodes.Mesh053 as THREE.Mesh).geometry}
              material={materials.Crane3yellow}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={(nodes.Mesh053_1 as THREE.Mesh).geometry}
              material={materials.Cglass}
            />
          </group>
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.CrainM13 as THREE.Mesh).geometry}
            material={materials.Crane3yellow}
            position={[106.81, 6.35, -0.18]}
            scale={0.81}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Cyl23 as THREE.Mesh).geometry}
            material={materials.Crane3yellow}
            position={[668.02, 937.27, -2.38]}
            scale={0.81}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Cyl24 as THREE.Mesh).geometry}
            material={materials.Crane3yellow}
            position={[626.27, 951.42, -1.96]}
            scale={0.81}
          />
          <group position={[100.55, 58.01, -82.33]} scale={0.81}>
            <mesh
              castShadow
              receiveShadow
              geometry={(nodes.Mesh057 as THREE.Mesh).geometry}
              material={materials.Crane3yellow}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={(nodes.Mesh057_1 as THREE.Mesh).geometry}
              material={materials.Cglass}
            />
          </group>
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Hook15 as THREE.Mesh).geometry}
            material={materials.Crane3yellow}
            position={[645.06, 636.34, -2.05]}
            scale={0.81}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Part11 as THREE.Mesh).geometry}
            material={materials.Crane3yellow}
            position={[212.67, 509.38, -0.4]}
            scale={0.81}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Wiper11 as THREE.Mesh).geometry}
            material={materials.Crane3yellow}
            position={[141.65, 69.43, -57.51]}
            scale={0.81}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.Wire11 as THREE.Mesh).geometry}
            material={materials.Crane3yellow}
            position={[462.78, 737.78, 8.05]}
            scale={0.81}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.Tower11 as THREE.Mesh).geometry}
          material={materials.Crane3yellow}
          position={[-278.28, -564.68, 1.35]}
        />
      </group> */}
      <mesh
        castShadow
        receiveShadow
        geometry={(nodes.Cube as THREE.Mesh).geometry}
        material={materials.Material}
      />
      {/* <group position={[4.11, 16.94, -9.62]} scale={0.03}>
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes. as THREE.Mesh)Mesh018.geometry}
          material={materials.Lamp01}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes. as THREE.Mesh)Mesh018_1.geometry}
          material={materials.Lamp01inside}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes. as THREE.Mesh)Mesh018_2.geometry}
          material={materials.neon01}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes. as THREE.Mesh)Mesh018_3.geometry}
          material={materials.neonhead}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes. as THREE.Mesh)Mesh018_4.geometry}
          material={materials.Vessel_White3DARK}
        />
      </group> */}
      <mesh
        castShadow
        receiveShadow
        geometry={(nodes.Drydock_C as THREE.Mesh).geometry}
        material={materials.U_Boat_pool}
        position={[-28.55, 9.81, 5.16]}
        scale={0.03}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={(nodes.FuelT01 as THREE.Mesh).geometry}
        material={materials.U_Boat_pool}
        position={[17.35, 23.02, -16.14]}
        scale={0.03}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={(nodes.S_Basement2 as THREE.Mesh).geometry}
        material={materials.U_Boat_pool}
        position={[-24.92, 8.18, 5.03]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.03}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={(nodes.Shipbasement as THREE.Mesh).geometry}
        material={materials.U_Boat_pool}
        position={[-139.87, 6.49, 1.89]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={[0.03, 0.04, 0.03]}
      />
      <group position={[0, 2, 0]}>
        <group position={[5, 0, 0]}>
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.RoofWaterTank as THREE.Mesh).geometry}
            material={materials.ConstTankDivMATT}
            position={[67.51, 19.49, 23.26]}
            rotation={[0, 1.57, 0]}
            scale={0.03}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.ChemWatertank as THREE.Mesh).geometry}
            material={materials.ConstTankDivMATT}
            position={[67.89, 18.36, 16.35]}
            rotation={[0, 1.57, 0]}
            scale={0.03}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.ChemWatertank01 as THREE.Mesh).geometry}
            material={materials.ConstTankDivMATT}
            position={[67.47, 18.36, 18.1]}
            rotation={[0, 1.57, 0]}
            scale={0.03}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.RoofWaterTank01 as THREE.Mesh).geometry}
            material={materials.ConstTankDivMATT}
            position={[67.48, 19.49, 3.54]}
            rotation={[0, 1.57, 0]}
            scale={0.03}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.RoofWaterTank02 as THREE.Mesh).geometry}
            material={materials.ConstTankDivMATT}
            position={[-15.81, 19.49, -15.58]}
            scale={0.03}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.BarrelE as THREE.Mesh).geometry}
            material={materials.ConstTankDiv}
            position={[66.68, 18.53, 9.35]}
            rotation={[0, 1.57, 0]}
            scale={0.03}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.BarrelD as THREE.Mesh).geometry}
            material={materials.ConstTankDiv}
            position={[68.23, 18.53, 10.03]}
            rotation={[0, 1.57, 0]}
            scale={0.03}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.BarrelA as THREE.Mesh).geometry}
            material={materials.ConstTankDiv}
            position={[66.91, 18.53, 14.19]}
            rotation={[0, 1.57, 0]}
            scale={0.03}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.BarrelC as THREE.Mesh).geometry}
            material={materials.ConstTankDiv}
            position={[67.71, 18.53, 12.6]}
            rotation={[0, Math.PI / 2, 0]}
            scale={0.03}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.BarrelB as THREE.Mesh).geometry}
            material={materials.ConstTankDiv}
            position={[67.7, 18.53, 13.58]}
            rotation={[0, 1.57, 0]}
            scale={0.03}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.BarrelF as THREE.Mesh).geometry}
            material={materials.ConstTankDiv}
            position={[68.17, 18.53, 8.19]}
            rotation={[0, 1.57, 0]}
            scale={0.03}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.PlasBarrelA as THREE.Mesh).geometry}
            material={materials.ConstTankDiv}
            position={[66.45, 18.53, 7.34]}
            rotation={[0, 1.57, 0]}
            scale={0.03}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.PlasBarrelB as THREE.Mesh).geometry}
            material={materials.ConstTankDiv}
            position={[66.45, 18.53, 6.46]}
            rotation={[0, 1.57, 0]}
            scale={0.03}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.BarrelE01 as THREE.Mesh).geometry}
            material={materials.ConstTankDiv}
            position={[67.71, 18.53, 7.21]}
            rotation={[0, 1.57, 0]}
            scale={0.03}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={(nodes.BarrelC01 as THREE.Mesh).geometry}
            material={materials.ConstTankDiv}
            position={[68.7, 18.53, 11]}
            rotation={[0, Math.PI / 2, 0]}
            scale={0.03}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.BarrelF01 as THREE.Mesh).geometry}
          material={materials.ConstTankDiv}
          position={[-21.28, 18.53, -12.78]}
          rotation={[-Math.PI, 0.25, -Math.PI]}
          scale={0.03}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.BarrelC02 as THREE.Mesh).geometry}
          material={materials.ConstTankDiv}
          position={[-18.42, 18.53, -12.6]}
          rotation={[-Math.PI, 0.25, -Math.PI]}
          scale={0.03}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.BarrelD01 as THREE.Mesh).geometry}
          material={materials.ConstTankDiv}
          position={[-19.48, 18.53, -12.39]}
          rotation={[-Math.PI, 0.25, -Math.PI]}
          scale={0.03}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.BarrelE02 as THREE.Mesh).geometry}
          material={materials.ConstTankDiv}
          position={[-22.35, 18.53, -12.57]}
          rotation={[-Math.PI, 0.25, -Math.PI]}
          scale={0.03}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.BarrelE03 as THREE.Mesh).geometry}
          material={materials.ConstTankDiv}
          position={[-57.6, 18.53, -2.28]}
          rotation={[0, -1.46, 0]}
          scale={0.03}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.BarrelD02 as THREE.Mesh).geometry}
          material={materials.ConstTankDiv}
          position={[-59.21, 18.53, -2.79]}
          rotation={[0, -1.46, 0]}
          scale={0.03}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.BarrelA01 as THREE.Mesh).geometry}
          material={materials.ConstTankDiv}
          position={[-58.34, 18.53, -7.07]}
          rotation={[0, -1.46, 0]}
          scale={0.03}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.BarrelC03 as THREE.Mesh).geometry}
          material={materials.ConstTankDiv}
          position={[-58.96, 18.53, -5.41]}
          rotation={[0, -1.46, 0]}
          scale={0.03}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.BarrelB01 as THREE.Mesh).geometry}
          material={materials.ConstTankDiv}
          position={[-59.06, 18.53, -6.38]}
          rotation={[0, -1.46, 0]}
          scale={0.03}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.BarrelF02 as THREE.Mesh).geometry}
          material={materials.ConstTankDiv}
          position={[-58.95, 18.53, -0.97]}
          rotation={[0, -1.46, 0]}
          scale={0.03}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.PlasBarrelA01 as THREE.Mesh).geometry}
          material={materials.ConstTankDiv}
          position={[-57.15, 18.53, -0.3]}
          rotation={[0, -1.46, 0]}
          scale={0.03}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.PlasBarrelB01 as THREE.Mesh).geometry}
          material={materials.ConstTankDiv}
          position={[-57.06, 18.53, 0.57]}
          rotation={[0, -1.46, 0]}
          scale={0.03}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.BarrelE04 as THREE.Mesh).geometry}
          material={materials.ConstTankDiv}
          position={[-58.38, 18.53, -0.04]}
          rotation={[0, -1.46, 0]}
          scale={0.03}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.BarrelC04 as THREE.Mesh).geometry}
          material={materials.ConstTankDiv}
          position={[-59.78, 18.53, -3.71]}
          rotation={[0, -1.46, 0]}
          scale={0.03}
        />
      </group>
      <group position={[-57.92, 20.39, 19.29]} scale={0.33}>
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.Mesh020 as THREE.Mesh).geometry}
          material={materials.Shelter}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.Mesh020_1 as THREE.Mesh).geometry}
          material={materials.Shelter2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.Mesh020_2 as THREE.Mesh).geometry}
          material={materials.Generator}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.Mesh020_3 as THREE.Mesh).geometry}
          material={materials.Generator2}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/drydock.glb')

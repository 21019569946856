import { useFrame, useThree } from '@react-three/fiber'
import { useState, useRef, useLayoutEffect, Fragment, useEffect } from 'react'
import * as THREE from 'three'
import FlightDeckBillBoardMain from './FlightDeckBillBoardMain'
import { useBounds, useGLTF, useHelper } from '@react-three/drei'
import {
  rolodexComponentEntry,
  rolodexInspectionEntry,
  rolodexUnitEntry,
  model,
} from 'api/interfaces'
import DestroyerHullDemo from '../models/DestroyerHullDemo'
import { components, gltfs } from './configs'

import SiloShaders from '../models/SiloShaders'
import { Object3D, Texture, Vector3 } from 'three'
import { update } from '@react-spring/web'

// TODO refactor this and don't use hardcoded globals
let wastage_112 = false
let wastage_115 = false
let wastage_118 = false
let wastage_9 = false

interface experienceProps {
  zoomedInFinal: boolean
  setZoomedInFinal: (bool: boolean) => void
  inspectionClicked: boolean
  setInspectionClicked: (componentId: string) => void
  repairs: boolean
  wastage: boolean
  inspectionLayers: Map<string, Map<string, boolean>>
  inspectionTextures: Map<string, Map<string, Texture>>
  inspectionVisibility: boolean
  inspectionData: { entry: rolodexInspectionEntry }[]
  chosenInspectionData: any
  componentData: { entry: rolodexComponentEntry }[]
  unitData: { entry: rolodexUnitEntry }[]
  reload: () => void
}

export default function Experience(props: experienceProps) {
  const [zoomedOutFinal, setZoomedOutFinal] = useState(true)
  const [cameraMoving, setCameraMoving] = useState(false)
  const [demoDestroyer, setDemoDestroyer] = useState(false)
  const directionalLight = useRef<any>()
  const shadowCameraRef = useRef<any>()
  useHelper(directionalLight, THREE.DirectionalLightHelper)
  useHelper(shadowCameraRef, THREE.CameraHelper)
  // @ts-ignore
  const { nodes, materials } = useGLTF(
    // @ts-ignore
    gltfs[props.unitData[0].entry.data.shipview.asset_type]
  )

  const setZoom = () => {
    zoomedOutFinal === true ? setZoomedOutFinal(false) : setZoomedOutFinal(true)

    if (zoomedOutFinal === false) {
      {
        props.setZoomedInFinal(false)
      }
    }
    if (zoomedOutFinal === true) {
      {
        props.setZoomedInFinal(true)
      }
    }
    setCameraMoving(false)
    return null
  }

  const zooming = () => {
    setCameraMoving(true)
    props.setZoomedInFinal(false)
    return null
  }

  useEffect(() => {
    //check if any of the inspections have shipview.destroyer_hull_demo === true
    //if so, set demoDestroyer to true
    props.inspectionData.forEach((inspection: { entry: rolodexInspectionEntry }) =>
      inspection.entry.data.shipview.destroyer_hull_demo === true
        ? setDemoDestroyer(true)
        : null
    )
  }, [])

  const renderAsset = (assetType: string) => {
    // if the asset type is "drydock" skip it
    if (assetType === 'drydock') {
      return <></>
    } else {
      // @ts-ignore
      let Tag = components[assetType]
      return (
        <Tag 
          wastage_112 = {wastage_112} 
          wastage_115 = {wastage_115}
          wastage_118 = {wastage_118}
          wastage_9 = {wastage_9}
        />
      )
    }
  }

  let dummy: string = '404'
  if (props.chosenInspectionData !== '' && props.chosenInspectionData !== null) {
    dummy = props.chosenInspectionData.id
  }

  return (
    <>
      {demoDestroyer === true ? (
        <DestroyerHullDemo repairs={props.repairs} wastage={props.wastage} />
      ) : (
        <></>
      )}

      {props.unitData[0].entry.data.shipview.models.length > 0 ? (
        props.unitData[0].entry.data.shipview.models.map((model: model) => (
          <>
            {/*{console.log(model.name)}*/}
            <group
              key={model.name}
              scale={model.scale}
              rotation={model.rotation}
              position={model.position}>
              {renderAsset(model.name)}
            </group>
          </>
        ))
      ) : (
        <></>
      )}

      <SiloShaders
        unitData={props.unitData[0]}
        inspectionData={props.inspectionData}
        clickedInspectionId={dummy}
        layerVisibility={props.inspectionLayers}
        inspectionTextures={props.inspectionTextures}
        inspectionVisibility={props.inspectionVisibility}
        meshNodes={nodes}
        zoomedInFinal={props.zoomedInFinal}
        zoomedOutFinal={zoomedOutFinal}
        reload={props.reload}
      />
      <Clicked
        inspectionClicked={props.inspectionClicked}
        setZoom={setZoom}
        zoomedInFinal={props.zoomedInFinal}
        zoomedOutFinal={zoomedOutFinal}
        zooming={zooming}
        cameraMoving={cameraMoving}
        inspectionData={props.inspectionData}
        inspectionClickedData={props.chosenInspectionData}
        componentData={props.componentData}
        unitData={props.unitData}
        meshNodes={nodes}
      />
    </>
  )
}

interface LineProps {
  start: number[]
  end: number[]
  children?: any
}

function Line({ start, end, children }: LineProps) {
  const ref = useRef<any>()
  useLayoutEffect(() => {
    ref.current.geometry.setFromPoints(
      [start, end].map((point) => new THREE.Vector3(...point))
    )
  }, [start, end])
  return (
    <line ref={ref}>
      <bufferGeometry />
      <lineBasicMaterial color={'black'} />
    </line>
  )
}

interface ClickedProps {
  inspectionClicked: boolean
  setZoom: () => void
  zoomedInFinal: boolean
  zoomedOutFinal: boolean
  zooming: () => void
  cameraMoving: boolean
  inspectionData: { entry: rolodexInspectionEntry }[]
  inspectionClickedData: rolodexInspectionEntry
  componentData: { entry: rolodexComponentEntry }[]
  unitData: { entry: rolodexUnitEntry }[]
  meshNodes: any
}

function Clicked(props: ClickedProps) {
  const [inspectionClicked, setInspectionCLicked] = useState(false)
  const [zoomedOutFinal, setZoomedOutFinal] = useState(true)
  const [zoomedInFinal, setZoomedInFinal] = useState(false)
  const [cameraMoving, setCameraMoving] = useState(false)
  const [debug, setDebug] = useState<JSX.Element>(<></>)

  useEffect(() => {
    if (
      !(!props.inspectionClicked && props.zoomedOutFinal) &&
      !(props.inspectionClicked && props.zoomedInFinal) &&
      !props.cameraMoving
    ) {
      props.zooming()
    }
  }, [inspectionClicked, zoomedOutFinal, zoomedInFinal, cameraMoving])

  if (props.inspectionClicked !== inspectionClicked) {
    setInspectionCLicked(props.inspectionClicked)
  }
  if (props.zoomedOutFinal !== zoomedOutFinal) {
    setZoomedOutFinal(props.zoomedOutFinal)
  }
  if (props.zoomedInFinal !== zoomedInFinal) {
    setZoomedInFinal(props.zoomedInFinal)
  }
  if (props.cameraMoving !== cameraMoving) {
    setCameraMoving(props.cameraMoving)
  }

  if (props.inspectionClicked === false && props.zoomedOutFinal === true) {
    return (
      <>
        {/* {props.inspectionData.map((inspection) => (
          <Fragment key={inspection.entry.id}>
            <FlightDeckBillBoardMain
              inspection={inspection}
              inspectionId={inspection.entry.id}
              componentData={props.componentData}
              inspectionData={props.inspectionData}
            />
            <Line
              start={inspection.entry.data.shipview.billboard.line_start}
              end={inspection.entry.data.shipview.billboard.line_end}
            />
          </Fragment>
        ))} */}
        {debug}
      </>
    )
  } else if (props.inspectionClicked === true && props.zoomedInFinal === true) {
    return (
      <>
        {debug}
        {/*<ColorBar inspectionClickedData={props.inspectionClickedData} />*/}
      </>
    )
  } else if (props.cameraMoving === false) {
    wastage_112 = false
    wastage_115 = false
    wastage_118 = false
    wastage_9 = false
    return debug
  } else {
    if (props.unitData[0].entry.id === "sentinel-class-cutter-306782") {
        return (
          <LookAtPlate
          inspectionClicked={props.inspectionClicked}
          setZoom={props.setZoom}
          inspectionClickedData={props.inspectionClickedData}
          unitData={props.unitData[0]}
          meshNode={
            props.meshNodes[
              props.inspectionClickedData.data.shipview.inspection_display.mesh
            ]
          }
          setDebug={setDebug}
          />
        )
    } else {
      return (
        <LookAtFlightDeck
          inspectionClicked={props.inspectionClicked}
          setZoom={props.setZoom}
          inspectionClickedData={props.inspectionClickedData}
          unitData={props.unitData[0]}
          meshNode={
            props.meshNodes[
              props.inspectionClickedData.data.shipview.inspection_display.mesh
            ]
          }
          setDebug={setDebug}
          />
      )
    }

  }
}

interface LookAtPlateProps {
  inspectionClicked: boolean
  setZoom: () => void
  inspectionClickedData: rolodexInspectionEntry
  unitData: { entry: rolodexUnitEntry }
  meshNode: Object3D
  setDebug: (box: JSX.Element) => void
}

function LookAtPlate(props: LookAtPlateProps) {
  const defaultCameraPosition = props.unitData.entry.data.shipview.camera_default_pos
  const defaultCameraRotation = props.unitData.entry.data.shipview.camera_default_rot
  const modelParams = props.unitData.entry.data.shipview.models[0]
  const boundingBox = new THREE.Box3()
  const inspectionGeometry =
    props.inspectionClickedData.data.shipview.inspection_display.geometry_type

  let object: Object3D = new Object3D()
  object = props.meshNode.clone()

  let rot = new THREE.Euler(
    modelParams.rotation[0] + object.rotation.x,
    modelParams.rotation[1] + object.rotation.y,
    modelParams.rotation[2] + object.rotation.z
  )
  object.setRotationFromEuler(rot)
  boundingBox.setFromObject(object)

  let center = new Vector3()
  let size = new Vector3()
  let offset = new Vector3(
    modelParams.position[0],
    modelParams.position[1],
    modelParams.position[2]
  )
  center = boundingBox.getCenter(center)

  boundingBox.translate(offset)
  center = boundingBox.getCenter(center)
  size = boundingBox.getSize(size)

  center = center.multiplyScalar(modelParams.scale)
  size = size.multiplyScalar(modelParams.scale)
  size = inverseRotationSize(size, object.rotation, inspectionGeometry)

  center = new THREE.Vector3(-2.5, 0.3, 0)

  let vec = new THREE.Vector3()

  useFrame(({ camera, controls, clock }) => {
    const dt = clock.getDelta()
    if (props.inspectionClicked == true) {
      //if it is a horizontal surface change the camera z rotation to 0
      const horizontalSurfaceExists =
        props.inspectionClickedData.data.shipview.inspection_display.hasOwnProperty(
          'horizontal_surface'
        )

      let cp = getCameraPos(size, center, object.rotation, inspectionGeometry)

      // I'll generalize this later
      const plate_id = props.inspectionClickedData.data.shipview.inspection_display.mesh
      if (plate_id === "60A61-126") {
        center = new THREE.Vector3(-2.5, 0.3, 0)
        cp = new THREE.Vector3(-2.5, -1, 1.5)
        wastage_112 = true
      } else if (plate_id === "60A61-276") {
        center = new THREE.Vector3(-4, 0.3, 0)
        cp = new THREE.Vector3(-4, -1, 1.5)
        wastage_115 = true
      } else if (plate_id === "60A61-278") {
        center = new THREE.Vector3(-4, 0.3, 0)
        cp = new THREE.Vector3(-4, -1, 1.5)
        wastage_118 = true
      } else if (plate_id === "30A33-012") {
        center = new THREE.Vector3(0.5, 2, 0)
        cp = new THREE.Vector3(0.4, 1, -2.5)
        wastage_9 = true
      }
      
      camera.position.lerp(cp, Math.max(dt * 40, 0.04))
      camera.lookAt(center.x, center.y, center.z)
      camera.rotation.set(
        camera.rotation.x + Math.PI/8,
        camera.rotation.y,
        horizontalSurfaceExists ? 0 : camera.rotation.z
      )
      if (
        camera.position.x.toFixed(2) === cp.x.toFixed(2) &&
        camera.position.y.toFixed(2) === cp.y.toFixed(2) &&
        camera.position.z.toFixed(2) === cp.z.toFixed(2)
      ) {
        props.setZoom()
      } else {
        return null
      }
    } else {
      vec.set(
        defaultCameraPosition[0],
        defaultCameraPosition[1],
        defaultCameraPosition[2]
      )
      camera.position.lerp(vec, Math.max(dt * 40, 0.04))
      camera.rotation.set(
        defaultCameraRotation[0],
        defaultCameraRotation[1],
        defaultCameraRotation[2]
      )

      if (
        camera.position.x.toFixed(0) === defaultCameraPosition[0].toFixed(0) &&
        camera.position.y.toFixed(0) === defaultCameraPosition[1].toFixed(0) &&
        camera.position.z.toFixed(0) === defaultCameraPosition[2].toFixed(0)
      ) {
        props.setZoom()
      }
    }
  })

  return null
}

interface LookAtFlightDeckProps {
  inspectionClicked: boolean
  setZoom: () => void
  inspectionClickedData: rolodexInspectionEntry
  unitData: { entry: rolodexUnitEntry }
  meshNode: Object3D
  setDebug: (box: JSX.Element) => void
}

function LookAtFlightDeck(props: LookAtFlightDeckProps) {
  const defaultCameraPosition = props.unitData.entry.data.shipview.camera_default_pos
  const defaultCameraRotation = props.unitData.entry.data.shipview.camera_default_rot
  const modelParams = props.unitData.entry.data.shipview.models[0]
  const boundingBox = new THREE.Box3()
  const inspectionGeometry =
    props.inspectionClickedData.data.shipview.inspection_display.geometry_type

  let object: Object3D = new Object3D()
  object = props.meshNode.clone()

  let rot = new THREE.Euler(
    modelParams.rotation[0] + object.rotation.x,
    modelParams.rotation[1] + object.rotation.y,
    modelParams.rotation[2] + object.rotation.z
  )
  object.setRotationFromEuler(rot)
  boundingBox.setFromObject(object)

  let center = new Vector3()
  let size = new Vector3()
  let offset = new Vector3(
    modelParams.position[0],
    modelParams.position[1],
    modelParams.position[2]
  )
  center = boundingBox.getCenter(center)

  boundingBox.translate(offset)
  center = boundingBox.getCenter(center)
  size = boundingBox.getSize(size)

  center = center.multiplyScalar(modelParams.scale)
  size = size.multiplyScalar(modelParams.scale)
  size = inverseRotationSize(size, object.rotation, inspectionGeometry)

  let vec = new THREE.Vector3()

  useFrame(({ camera, controls, clock }) => {
    const dt = clock.getDelta()
    if (props.inspectionClicked == true) {
      //if it is a horizontal surface change the camera z rotation to 0
      const horizontalSurfaceExists =
        props.inspectionClickedData.data.shipview.inspection_display.hasOwnProperty(
          'horizontal_surface'
        )

      const cp = getCameraPos(size, center, object.rotation, inspectionGeometry)
      camera.position.lerp(cp, Math.max(dt * 40, 0.04))
      camera.lookAt(center.x, center.y, center.z)
      camera.rotation.set(
        camera.rotation.x,
        camera.rotation.y,
        horizontalSurfaceExists ? 0 : camera.rotation.z
      )
      if (
        camera.position.x.toFixed(2) === cp.x.toFixed(2) &&
        camera.position.y.toFixed(2) === cp.y.toFixed(2) &&
        camera.position.z.toFixed(2) === cp.z.toFixed(2)
      ) {
        props.setZoom()
      } else {
        return null
      }
    } else {
      vec.set(
        defaultCameraPosition[0],
        defaultCameraPosition[1],
        defaultCameraPosition[2]
      )
      camera.position.lerp(vec, Math.max(dt * 40, 0.04))
      camera.rotation.set(
        defaultCameraRotation[0],
        defaultCameraRotation[1],
        defaultCameraRotation[2]
      )

      if (
        camera.position.x.toFixed(0) === defaultCameraPosition[0].toFixed(0) &&
        camera.position.y.toFixed(0) === defaultCameraPosition[1].toFixed(0) &&
        camera.position.z.toFixed(0) === defaultCameraPosition[2].toFixed(0)
      ) {
        props.setZoom()
      }
    }
  })

  return null
}

function inverseRotationSize(
  size: THREE.Vector3,
  r: THREE.Euler,
  geometryType: string
): THREE.Vector3 {
  let rot = new THREE.Euler()

  if (geometryType !== 'cylinder') {
    rot.x = r.x
    rot.y = r.z
    rot.z = r.y
  } else {
    rot.x = r.x
    rot.y = r.y
    rot.z = r.z
  }

  size.applyEuler(rot)
  size.x = Math.abs(size.x)
  size.y = Math.abs(size.y)
  size.z = Math.abs(size.z)

  return size
}

function getCameraPos(
  size: THREE.Vector3,
  center: THREE.Vector3,
  rotation: THREE.Euler,
  geometryType: string
): THREE.Vector3 {
  let normal = new THREE.Vector3(0, 0, 1)
  const maxDim = Math.max(size.x, size.y, size.z)

  const fovz = 30 * (Math.PI / 180)
  const fovy = 120 * (Math.PI / 180)

  let rot = new THREE.Euler()

  if (geometryType === 's_plane' || geometryType === 'destroyerFlightTwoA') {
    rot.x = rotation.x - Math.PI / 2
    rot.y = -rotation.z
    rot.z = rotation.y
  } else if (geometryType === 'cylinder') {
    rot.x = rotation.x
    rot.y = rotation.y + Math.PI / 2
    rot.z = rotation.z
  } else if (geometryType === 'd_plane') {
    rot.x = rotation.x + Math.PI / 2
    rot.y = rotation.z + Math.PI / 2
    rot.z = rotation.y + Math.PI / 2
  } else {
    rot.x = -rotation.x
    rot.y = rotation.y
    rot.z = rotation.z
  }

  let cameraDistance = Math.abs((maxDim / 2) * Math.tan(fovz * 2)) * 1.2
  if (geometryType === 'destroyerFlightTwoA') {
    cameraDistance = cameraDistance * 1.4
  }

  normal.applyEuler(rot)
  normal = normal.multiplyScalar(cameraDistance)

  const cameraPosition = new THREE.Vector3(
    center.x + normal.x,
    center.y + normal.y,
    center.z + normal.z
  )

  return cameraPosition
}

import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as UserIcon } from 'assets/user-icon.svg'
import { useAuth0 } from '@auth0/auth0-react'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import React from 'react'
import { TopBarComp, Logo, Title } from './style'

export default function TopBar() {
  const { user, logout, isLoading, isAuthenticated } = useAuth0()
  const [showFallbackImg, setShowFallbackImg] = React.useState(false)
  const navigate = useNavigate()

  const [userNavigation] = React.useState<Array<{ name: string; path: string }>>([
    { name: 'Sign out', path: '/' },
  ])

  const handleImgError: React.ReactEventHandler<HTMLImageElement> = () => {
    setShowFallbackImg(true)
  }

  return (
    <TopBarComp>
      <Logo src='./gecko_logo.svg' />
      <Title>
        ShipView
      </Title>
      <Menu as='div' className='flex-none p-1'>
        <div className='flex'>
          <span style={{ color: 'white' }} className='self-center mr-2'>{`${
            user?.given_name ?? ''
          } ${user?.family_name ?? ''}`}</span>
          <Menu.Button className='flex items-center max-w-xs text-sm rounded-full focus:outline-none ring-2 ring-offset-2 ring-offset-gray-800 focus:ring-gray-600'>
            <span className='sr-only'>Open user menu</span>
            {isAuthenticated && !isLoading && !showFallbackImg ? (
              <img
                className='w-8 h-8 rounded-full'
                src={user?.picture}
                onError={handleImgError}
                alt='User profile'
              />
            ) : (
              <UserIcon className='w-8 h-8 rounded-full' />
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'>
          <Menu.Items className='absolute z-10 w-48 py-1 mt-8 origin-top-right bg-white rounded-md shadow-lg right-4 ring-1 ring-black ring-opacity-5 focus:outline-none'>
            {userNavigation.map((item) => (
              <Menu.Item key={item.name}>
                {({ active }: any) =>
                  item.name === 'Sign out' ? (
                    <Link
                      to='/'
                      className={`${
                        active ? 'bg-gray-100' : ''
                      } block px-4 py-2 text-sm text-gray-700`}
                      onClick={() =>
                        logout({ logoutParams: { returnTo: window.location.origin } })
                      }>
                      {item.name}
                    </Link>
                  ) : (
                    <Link
                      to={item.path}
                      className={`${
                        active ? 'bg-gray-100' : ''
                      } block px-4 py-2 text-sm`}>
                      {item.name}
                    </Link>
                  )
                }
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </TopBarComp>
  )
}

import styled from 'styled-components'

export const WastageColorBar = styled.div`
  position: relative;
  height: 20%;
  width: 10%;
  background: linear-gradient(to bottom, red, red);
`

export const RepairsColorBar = styled(WastageColorBar)`
  background: linear-gradient(to bottom, blue, blue);
`

export const HeatMapColorBar = styled(WastageColorBar)`
  background: linear-gradient(
    to bottom,
    rgb(1, 106, 103),
    rgb(127, 145, 56),
    rgb(255, 189, 10),
    rgb(225, 94, 30),
    rgb(194, 2, 50)
  );
  height: 40%;
`

export const Tick = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: white;
  opacity: 0;
`

export const TopTick = styled(Tick)`
  top: 0;
`

export const BottomTick = styled(Tick)`
  bottom: 0;
`

export const UnitText = styled.div`
  position: absolute;
  font-size: 1rem;
  color: white;
  white-space: nowrap;
  font-weight: 300;
`

export const TopUnitText = styled(UnitText)`
  top: 0;
  left: calc(100% + 5px); // Position it to the right of the color bar
  transform: translateY(-50%);
`

export const BottomUnitText = styled(UnitText)`
  bottom: 0;
  left: calc(100% + 5px); // Position it to the right of the color bar
  transform: translateY(50%);
`

export const ColorBarTitle = styled.div`
  position: absolute;
  top: 50%;
  right: calc(100% + 10px); /* Move the title to the left of the color bar */
  //   transform: translate(0, -50%) rotate(-90deg); /* Rotate and center the title */
  font-size: 1rem;
  font-weight: 300;
  color: white;
  white-space: nowrap;
`

import styled from 'styled-components'

export const DossierDiv = styled.div`
  width: 40rem;
  height: 100%;
  background: rgb(17, 17, 17);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  position: relative;
`

export const DossierUnitTitleDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const InspectionsOverviewDiv = styled.div`
  width: 100%;
`

export const ShipLogo = styled.img`
  width: 90px;
`

export const ShipLogoDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`

export const ShipName = styled.h1`
  margin: 1rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 700;
  text-align: center;
  text-overflow: ellipsis;
  max-width: 100%;
  font-family: Manrope;
  letter-spacing: 2px;
`

export const InspectionsOverflowDiv = styled.div`
  width: 100%;
  overflow-y: auto;
`

export const InspectionsOverviewSpanContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  padding-left: 5rem;
`

export const InspectionOverviewSpan = styled.span`
  font-weight: 500;
  font-family: Manrope;
  font-size: 1.25rem;
  line-height: 1;
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 23rem;
  color: rgb(162, 186, 195);
`

export const FlightDeckInspectionDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  padding: 1rem 0;
  &:hover {
    cursor: pointer;
  }
`

export const FlightDeckInspectionDivLow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  padding: 1rem 0;
`

export const TokaLogoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border: 2px solid rgb(209, 222, 227);
  border-radius: 100%;
  background-color: rgb(49, 110, 212);
  margin-left: 2.5rem;
`

export const ComponentLogo = styled.svg`
  border-radius: 100%;
  width: 2.75rem;
  height: 2.75rem;
`

export const UnitLabel = styled.h4`
  width: 67%;
  color: rgb(162, 186, 195);
  align-items: center;
  font-size: 1.5rem;
  
  font-family: Manrope;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 30px;
`

export const ButtonSVG = styled.svg`
  margin-top: 1.5rem;
  &:hover {
    cursor: pointer;
  }
`

export const LineBreak = styled.hr`
  width: 90%;
  margin: 0.75rem 1.5rem;
  border-color: rgb(40, 61, 77);
  border-radius: 1rem;
`

export const LineBreakSmall = styled.hr`
  width: 70%;
  margin: 0.75rem 1.5rem;
  border-color: rgb(40, 61, 77);
  border-radius: 1rem;
`

export const CenterLineBreakDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

import React, { useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import CustomMaterial from './Materials/Material'
import {rolodexInspectionEntry, rolodexUnitEntry} from '../../api/interfaces'
import { Box3, SpriteMaterial, Texture, Vector3 } from 'three'
import { ThreeEvent } from '@react-three/fiber/dist/declarations/src/core/events'

export type SiloShadersProps = {
  unitData:{ entry: rolodexUnitEntry }
  inspectionData: { entry: rolodexInspectionEntry }[]
  clickedInspectionId: string
  layerVisibility: Map<string, Map<string, boolean>>
  inspectionTextures: Map<string, Map<string, Texture>>
  inspectionVisibility: boolean
  meshNodes: any
  zoomedInFinal: boolean
  zoomedOutFinal: boolean
  reload: () => void
}

export default function SiloShaders(props: SiloShadersProps) {
  const modelParams = props.unitData.entry.data.shipview.models[0]
  const heatMaps = props.inspectionTextures
  const [rotationAmount, setRotationAmount] = useState<number>(0)
  const [scrollTEXTure, setScrollTEXTure] = useState<Texture>()
  const [inspectionBox, setInspectionBox] = useState<Box3>(new Box3())
  const rotateAmount = (rotation: number) => {
    setRotationAmount(rotation + rotationAmount)
  }

  useEffect(() => {
    if (!props.zoomedInFinal && rotationAmount !== 0) {
      setRotationAmount(0)
    }
  }, [rotationAmount, props.clickedInspectionId, props.zoomedInFinal])

  useEffect(() => {
    for (let i = 0; i < props.inspectionData.length; i++) {
      if (props.inspectionData[i].entry.id === props.clickedInspectionId) {
        const thing = new THREE.Box3().setFromObject(
          props.meshNodes[
            props.inspectionData[i].entry.data.shipview.inspection_display.mesh
          ]
        )
        let center = new Vector3()
        setInspectionBox(thing)
        break
      }
    }
  }, [props.clickedInspectionId])

  useEffect(() => {
    const t = new THREE.TextureLoader().load('scroll.png')
    setScrollTEXTure(t)
  }, [])

  useEffect(() => {}, [])

  const rotatable = (inspection: { entry: rolodexInspectionEntry }) => {
    return (
      inspection.entry.id === props.clickedInspectionId &&
      inspection.entry.data.shipview.inspection_display.geometry_type === 'cylinder' &&
      props.zoomedInFinal
    )
  }

  return (
    <group
      {...props}
      dispose={null}
      position={modelParams.position}
      rotation={modelParams.rotation}
      scale={modelParams.scale}>
      {props.inspectionData.map((inspection: { entry: rolodexInspectionEntry }) => (
        <group key={inspection.entry.id}>
          <group
            position={
              props.meshNodes[inspection.entry.data.shipview.inspection_display.mesh]
                .position
            }
            rotation={
              props.meshNodes[inspection.entry.data.shipview.inspection_display.mesh]
                .rotation
            }
            onWheel={(event: ThreeEvent<MouseEvent>) => {
              if (!event.uv) return

              if (rotatable(inspection)) {
                // @ts-ignore
                rotateAmount(event.nativeEvent.deltaY)
              }
            }}>
            <mesh
              castShadow
              receiveShadow
              geometry={
                (
                  props.meshNodes[
                    inspection.entry.data.shipview.inspection_display.mesh
                  ] as THREE.Mesh
                ).geometry
              }
              scale={
                props.meshNodes[inspection.entry.data.shipview.inspection_display.mesh]
                  .scale
              }>
              <CustomMaterial
                inspectionData={inspection.entry}
                clickedInspectionId={props.clickedInspectionId}
                layerVisibility={props.layerVisibility}
                inspectionVisibility={
                  inspection.entry.id === props.clickedInspectionId
                    ? props.inspectionVisibility
                    : false
                }
                // @ts-ignore
                layerTextures={heatMaps.get(inspection.entry.id)}
                zoomedInFinal={props.zoomedInFinal}
                zoomedOutFinal={props.zoomedOutFinal}
                rotation={rotationAmount}
                dummyTexture={scrollTEXTure}
                toggle={props.reload}
              />
            </mesh>
          </group>
          {rotatable(inspection) && rotationAmount === 0 ? (
            <sprite
              position={[
                inspectionBox.getSize(new Vector3()).x,
                inspectionBox.getSize(new Vector3()).y / 2,
                0,
              ]}
              rotation={[0, 0, 0]}
              material={new SpriteMaterial({ map: scrollTEXTure })}
              scale={4}></sprite>
          ) : (
            <></>
          )}
        </group>
      ))}
    </group>
  )
}

import * as THREE from 'three'
import { Plane } from '@react-three/drei'

export default function Staging() {
  return (
    <>
      <directionalLight intensity={1} position={[0, 2, 1]} />
      {/* <KeyLight brightness={15.6} color='#F5F5F5' /> */}
      {/* <FillLight brightness={2.6} color='#F5F5F5' /> */}
      <RimLight brightness={54} color='#F5F5F5' />
      <Ground />
    </>
  )
}

interface LightProps {
  brightness: number
  color: string
}

// Lights
function KeyLight(props: LightProps) {
  return (
    <>
      <rectAreaLight
        width={3}
        height={3}
        color={props.color}
        intensity={props.brightness}
        position={[-2, 5, 5]}
      />
      <spotLight
        color={props.color}
        intensity={props.brightness * 0.01}
        position={[-2, 5, 5]}
        angle={0.5}
        penumbra={1}
        castShadow
        shadow-mapSize={[64, 64]}
      />
    </>
  )
}

function FillLight(props: LightProps) {
  return (
    <>
      <rectAreaLight
        width={3}
        height={3}
        intensity={props.brightness}
        color={props.color}
        position={[2, 5, 4]}
      />
      <spotLight
        color={props.color}
        intensity={props.brightness * 0.01}
        position={[2, 5, 4]}
        angle={0.5}
        penumbra={1}
        castShadow
        shadow-mapSize={[64, 64]}
      />
    </>
  )
}

function RimLight(props: LightProps) {
  return (
    <>
      <rectAreaLight
        width={2}
        height={2}
        intensity={props.brightness}
        color={props.color}
        position={[1, -10, -2]}
        rotation={[0, 180, 0]}
      />
      <spotLight
        color={props.color}
        intensity={props.brightness * 0.01}
        position={[1, -10, -2]}
        angle={0.5}
        penumbra={1}
        castShadow
        shadow-mapSize={[64, 64]}
      />
    </>
  )
}

function Ground() {
  const groundMaterial = new THREE.MeshStandardMaterial({ color: '#333333' }) // Choose a color for the ground

  return (
    <Plane
      args={[50, 10]} // Set the size of the ground plane
      rotation={[-Math.PI / 2, 0, 0]} // Rotate the plane to be horizontal
      position={[0, -1, 0]} // Adjust the position so the model appears to be floating above the surface
      receiveShadow // Make sure the ground plane receives shadows
    >
      <meshStandardMaterial attach='material' {...groundMaterial} />
    </Plane>
  )
}

import React, { useEffect } from 'react'
import styled from 'styled-components'

const OperationalStatusRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
`

const OperationalStatusSubRow = styled.div`
  display: flex;
  justify-content: baseline;
  gap: 1.5rem;
  align-items: center;
`

const OperationalStatus = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
`

const Subtitle = styled.h2`
  color: white;
  font-size: 0.9rem;
  font-weight: 400;
`

const VerticalLineBreak = styled.div`
  width: 0.5rem;
  height: 3rem;
  background-color: grey;
  // margin-right: 1rem;
`

interface StatsOperationalStatusWidgetProps {
  operationalStatus: string
}

function StatsOperationalStatusWidget(props: StatsOperationalStatusWidgetProps) {
  const [operationalStatusColor, setOperationalStatusColor] = React.useState('')

  const getOperationalStatusColor = () => {
    const lowercaseOperationalStatusText = props.operationalStatus.toLowerCase()
    if (
      lowercaseOperationalStatusText === 'na' ||
      lowercaseOperationalStatusText === ''
    ) {
      setOperationalStatusColor('white')
    } else if (
      lowercaseOperationalStatusText === 'critical' ||
      lowercaseOperationalStatusText === 'sra'
    ) {
      setOperationalStatusColor('red')
    } else if (
      lowercaseOperationalStatusText === 'healthy' ||
      lowercaseOperationalStatusText === 'cmav'
    ) {
      setOperationalStatusColor('green')
    } else if (
      lowercaseOperationalStatusText === 'un-inspected' ||
      lowercaseOperationalStatusText === 'ssia'
    ) {
      setOperationalStatusColor('yellow')
    } else if (lowercaseOperationalStatusText === 'woo') {
      setOperationalStatusColor('blue')
    } else {
      setOperationalStatusColor('white')
    }
  }

  useEffect(() => {
    getOperationalStatusColor()
  }, [props.operationalStatus])

  if (props.operationalStatus === '' || operationalStatusColor === '') {
    return (
      <OperationalStatusRow>
        <OperationalStatus>"NA"</OperationalStatus>
        <OperationalStatusSubRow>
          <VerticalLineBreak style={{ backgroundColor: 'white' }} />
          <Subtitle style={{ color: 'white' }}>Availability Status</Subtitle>
        </OperationalStatusSubRow>
      </OperationalStatusRow>
    )
  } else {
    return (
      <OperationalStatusRow>
        <OperationalStatus>{props.operationalStatus}</OperationalStatus>
        <OperationalStatusSubRow>
          <VerticalLineBreak style={{ backgroundColor: operationalStatusColor }} />
          <Subtitle style={{ color: 'white' }}>Availability Status</Subtitle>
        </OperationalStatusSubRow>
      </OperationalStatusRow>
    )
  }
}

export default React.memo(StatsOperationalStatusWidget)

import { titleWidget } from 'api/interfaces'
import React from 'react'
import styled from 'styled-components'

const Title = styled.h1`
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
`

interface StatsTitleWidgetProps {
  title: titleWidget
}
function StatsTitleWidget(props: StatsTitleWidgetProps) {
  return <Title>{props.title?.value}</Title>
}

export default React.memo(StatsTitleWidget)

import React, { useEffect, useState } from 'react'

import * as THREE from 'three'
import { Color, CustomBlending, ImageLoader, ShaderMaterial, Texture } from 'three'
// import { VizGridMode } from '../Utility/VizGridMode'

import { GeckoTheme } from 'gecko-ui'

import fragmentShader from '../Shaders/fragmentShader'
import vertexShader from '../Shaders/vertexShader'
import { rolodexInspectionEntry } from '../../../api/interfaces'

export type MaterialProps = {
  rotation: number
  inspectionData: rolodexInspectionEntry
  clickedInspectionId: string
  layerVisibility: Map<string, Map<string, boolean>>
  inspectionVisibility: boolean
  layerTextures: Map<string, Texture>
  zoomedInFinal: boolean
  dummyTexture: Texture | undefined
  toggle: () => void
}
export default function CustomMaterial(props: MaterialProps) {
  const shipViewData = props.inspectionData.data.shipview
  const textureData = shipViewData.inspection_display.textures
  const [rot, setRot] = useState<number>(0)
  const [imageTextures, setImageTextures] = useState<Texture[]>([])
  const [numTextures, setNumTextures] = useState<number>(0)
  const maxTextures = textureData.length
  const [counter, setCounter] = useState<number>(0)

  const uniforms = React.useMemo(() => {
    let hits: number = 0
    if (props.layerVisibility.has(props.inspectionData.id)) {
      for (let i = 0; i < textureData.length; i++) {
        if (
          // @ts-ignore
          props.layerVisibility.get(props.inspectionData.id).get(textureData[i].name)
        ) {
          hits += 1
        }
      }
    }
    const v_scale = shipViewData.inspection_display.geometry_type === 'cylinder' ? 0.5 : 1
    const flip_uv_x_axis = shipViewData.inspection_display.geometry_type === 'cylinder'
    const flip_uv_y_axis =
      shipViewData.inspection_display.geometry_type === 'halfTank' ||
      shipViewData.inspection_display.geometry_type === 'destroyerFlightTwoA'
    //1.014
    return {
      u_textures: { type: 't', value: imageTextures },
      num_textures: { type: 'int', value: hits },
      u_uv_scale: { value: [1.014, v_scale] },
      flip_uv_x_axis: { type: 'boolean', value: flip_uv_x_axis },
      flip_uv_y_axis: { type: 'boolean', value: flip_uv_y_axis },
      rotation: { type: 'float', value: rot },
    }
  }, [])

  // if (imageTextures.length > 0 && counter === 0) {
  //   props.toggle()
  //   setCounter(counter + 1)
  // }

  React.useEffect(() => {
    let imgTex: Texture[] = []
    if (props.layerVisibility.has(props.inspectionData.id)) {
      for (let i = 0; i < textureData.length; i++) {
        if (
          // @ts-ignore
          props.layerVisibility.get(props.inspectionData.id).get(textureData[i].name)
        ) {
          // @ts-ignore
          imgTex.push(props.layerTextures.get(textureData[i].name))
        }
      }
    }

    let texlen = imgTex.length

    if (imgTex.length !== numTextures) {
      setNumTextures(imgTex.length)
    }
    const dummy_texture = new THREE.TextureLoader().load('scroll.png')
    for (let i = 0; i < maxTextures - texlen; i++) {
      imgTex.push(dummy_texture)
    }

    setImageTextures(imgTex)

    uniforms.u_textures.value = imgTex
    uniforms.num_textures.value = texlen
  }, [props.inspectionVisibility, props.zoomedInFinal])

  React.useEffect(() => {
    uniforms.rotation.value = 0
  }, [props.zoomedInFinal])

  React.useEffect(() => {
    if (
      props.inspectionData.id === props.clickedInspectionId &&
      props.inspectionData.data.shipview.inspection_display.geometry_type ===
        'cylinder' &&
      props.zoomedInFinal
    ) {
      const r = props.rotation / 1870.0
      uniforms.rotation.value = r - Math.floor(r)
    }
  }, [uniforms, props.rotation])

  return (
    <shaderMaterial
      fragmentShader={fragmentShader(maxTextures, 0.8)}
      vertexShader={vertexShader()}
      uniforms={uniforms}
      blending={CustomBlending}
      opacity={0.1}
      transparent={true}
    />
  )
}

// export function StaticMaterial(props: MaterialProps) {
//   const shipViewData = props.inspectionData.data.shipview
//   const textureData = shipViewData.inspection_display.textures
//   const maxTextures = textureData.length
//
//   let imgTex: Texture[] = []
//   if (props.layerVisibility.has(props.inspectionData.id)) {
//     for (let i = 0; i < textureData.length; i++) {
//       if (
//         // @ts-ignore
//         props.layerVisibility.get(props.inspectionData.id).get(textureData[i].name)
//       ) {
//         // @ts-ignore
//         imgTex.push(props.layerTextures.get(textureData[i].name))
//         console.log(props.layerTextures.get(textureData[i].name))
//       }
//     }
//   }
//
//   let texlen = imgTex.length
//
//   console.log(texlen)
//
//   const v_scale = shipViewData.inspection_display.geometry_type === 'cylinder' ? 0.5 : 1
//   let uniforms = {
//     u_textures: { type: 't', value: imgTex },
//     num_textures: { type: 'int', value: texlen },
//     u_uv_scale: { value: [1.014, v_scale] },
//     rotation: { type: 'float', value: props.rotation },
//   }
//
//   const mat = new THREE.ShaderMaterial({
//     uniforms: uniforms,
//     vertexShader: vertexShader(),
//     fragmentShader: fragmentShader(maxTextures, 0.8),
//     blending: CustomBlending,
//     opacity: 0.1,
//     transparent: true,
//   })
//
//   return mat
// }

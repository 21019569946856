import { DescriptionText, ImagesContainer, Image, TitleText } from './styles'

export const RepairsDialogContent = () => {
  return (
    <>
      <TitleText>
        <span style={{ color: 'grey' }}>Layer Description: </span>
        Repairs
      </TitleText>
      <DescriptionText>
        The repairs layer, generated by Gecko Robotics' software, Forecast, utilizes
        percentage wastage heatmaps from asset inspections to determine optimal repair
        strategies for assets.
      </DescriptionText>
      <DescriptionText>
        It identifies areas of material degradation and recommends suitable repair
        methods, such as where to cut and replace plates, or where to utilize weld
        overlay, based on user-defined standards and inputs.
      </DescriptionText>
      <ImagesContainer>
        <div>
          <Image src='./repairs_example.png'></Image>
        </div>
      </ImagesContainer>
    </>
  )
}

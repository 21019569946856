import { Fragment, useEffect } from 'react'
import {
  rolodexUnitEntry,
  rolodexComponentEntry,
  rolodexInspectionEntry,
} from 'api/interfaces'
import { useRolodexImage } from 'api/useRolodex'
import React from 'react'
import {
  DossierDiv,
  DossierUnitTitleDiv,
  ShipLogo,
  ShipName,
  LineBreak,
  InspectionsOverviewDiv,
  InspectionsOverviewSpanContainer,
  InspectionOverviewSpan,
  CenterLineBreakDiv,
  LineBreakSmall,
  InspectionsOverflowDiv,
  FlightDeckInspectionDiv,
  TokaLogoDiv,
  ComponentLogo,
  InspectionLabel,
  ShipLogoDiv,
  ButtonSVG,
  FlightDeckInspectionDivLow,
} from './style'
import { useSpring, animated } from '@react-spring/web'

interface dossierProps {
  inspectionClicked: boolean
  setInspectionClicked: (componentId: string) => void
  shipData: { entry: rolodexUnitEntry }[]
  componentData: { entry: rolodexComponentEntry }[]
  inspectionData: { entry: rolodexInspectionEntry }[]
  chosenComponent: string
  zoomedInFinal: boolean
}

export default function Dossier(props: dossierProps) {
  const [shipLogo, setShipLogo] = React.useState<any>('')

  const loadingShipLogo = useRolodexImage(
    `shipview-${props.inspectionData[0].entry.id}`,
    'shipview_unit_logo'
  )

  const { width }: any = useSpring({
    from: { width: '0' },
    width: !props.zoomedInFinal ? '40rem' : '0',
  })
  useEffect(() => {
    if (loadingShipLogo) {
      if (props.shipData[0].entry.id === 'sentinel-class-cutter-306782') {
        setShipLogo('/shipview_unit_logo.png')
      } else if (props.inspectionData[0].entry.tags.organization === 'ship-fleet') {
        setShipLogo('/generic_ship_logo.png')
      } else {
        setShipLogo(loadingShipLogo)
      }
    }
  }, [loadingShipLogo])

  if (!shipLogo) {
    return null
  } else {
    if (props.inspectionClicked === false) {
      return (
        <DossierDiv as={animated.div} style={{ width: width }}>
          <DossierUnitTitleDiv>
            <ShipLogo src={shipLogo} />
            <ShipName>
              {props.shipData.length > 0
                ? props.shipData[0].entry.data.portal.name
                : 'Unknown'}
            </ShipName>
            <LineBreak />
          </DossierUnitTitleDiv>
          <InspectionsOverviewDiv>
            <InspectionsOverviewSpanContainer>
              <InspectionOverviewSpan>Inspections Overview</InspectionOverviewSpan>
            </InspectionsOverviewSpanContainer>
            <CenterLineBreakDiv>
              <LineBreakSmall />
            </CenterLineBreakDiv>
            <InspectionsOverflowDiv>
              {props.componentData.length > 0
                ? props.componentData.map((component) => (
                    <Fragment key={component.entry.id}>
                      <FlightDeckInspectionDiv
                        onClick={() =>
                          props.setInspectionClicked(component.entry.data.portal.name)
                        }>
                        <TokaLogoDiv>
                          <ComponentLogo
                            xmlns='http://www.w3.org/2000/svg'
                            version='1'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'>
                            <path
                              fill='white'
                              d='M22,0h-4l-1.5,17.6L16.1,11H14V6l-7,5V6l-7,5v12v1h16h1h7L22,0z M5,21H3v-3h2V21z M5,16H3v-3h2V16z M9,21H7v-3h2V21z M9,16 H7v-3h2V16z M13,21h-2v-3h2V21z M13,16h-2v-3h2V16z'
                            />
                          </ComponentLogo>
                        </TokaLogoDiv>
                        <InspectionLabel>
                          {component.entry.data.portal.name}
                        </InspectionLabel>
                      </FlightDeckInspectionDiv>
                      <LineBreakSmall />
                    </Fragment>
                  ))
                : null}
            </InspectionsOverflowDiv>
          </InspectionsOverviewDiv>
        </DossierDiv>
      )
    } else {
      return (
        <DossierDiv as={animated.div} style={{ width: width }}>
          <DossierUnitTitleDiv>
            <ShipLogoDiv>
              <ButtonSVG
                onClick={() => props.setInspectionClicked('')}
                xmlns='http://www.w3.org/2000/svg'
                width='23.707'
                height='18.594'
                viewBox='0 0 23.707 18.594'>
                <path
                  id='back'
                  d='M32.442,27.779H18.257l3.013-3.013a.692.692,0,1,0-.979-.979L16.081,27.98a.692.692,0,0,0-.086.1v.028a.692.692,0,0,0-.048.09v.042a.592.592,0,0,0,0,.446v.042a.692.692,0,0,0,.048.09v.028a.692.692,0,0,0,.086.1l4.211,4.218a.692.692,0,0,0,.979-.979L18.24,29.163H32.425a5.667,5.667,0,0,1,0,11.331H16.572a.692.692,0,1,0,0,1.384h15.87a7.051,7.051,0,0,0,0-14.1Z'
                  transform='translate(-15.755 -23.409)'
                  fill='#7f949c'
                />
              </ButtonSVG>
              <ShipLogo src={shipLogo} />
              {/*//todo: i guess rename crap to make it more generalized and not confusing*/}
              <ButtonSVG
                style={{ opacity: 0 }}
                width='24'
                height='24'
                fill='white'
                xmlns='http://www.w3.org/2000/svg'>
                <path d='M18.707 6.707a1 1 0 0 0-1.414-1.414L12 10.586 6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 1 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12l5.293-5.293Z' />
              </ButtonSVG>
            </ShipLogoDiv>

            <ShipName>
              {' '}
              {props.shipData.length > 0
                ? props.shipData[0].entry.data.portal.name
                : 'Unknown'}
            </ShipName>
            <LineBreak />
          </DossierUnitTitleDiv>
          <InspectionsOverviewDiv>
            {/* <h3 style={{ color: "#A2BAC3" }}>Inspections Overview</h3> */}
            <FlightDeckInspectionDivLow>
              {/*//todo: rename things like this*/}
              <TokaLogoDiv>
                <ComponentLogo
                  xmlns='http://www.w3.org/2000/svg'
                  version='1'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'>
                  <path
                    fill='white'
                    d='M22,0h-4l-1.5,17.6L16.1,11H14V6l-7,5V6l-7,5v12v1h16h1h7L22,0z M5,21H3v-3h2V21z M5,16H3v-3h2V16z M9,21H7v-3h2V21z M9,16 H7v-3h2V16z M13,21h-2v-3h2V21z M13,16h-2v-3h2V16z'
                  />
                </ComponentLogo>
              </TokaLogoDiv>
              <InspectionLabel>{props.chosenComponent}</InspectionLabel>
            </FlightDeckInspectionDivLow>
          </InspectionsOverviewDiv>
        </DossierDiv>
      )
    }
  }
}

import Silo from '../models/Silo'
import Destroyer from '../models/Destroyer'
import DryDock from '../models/DryDock'
import Cutter from '../models/Cutter'
import HalfTank from 'components/models/HalfTank'
import DestroyerFlightTwoA from 'components/models/DestroyerFlightTwoA'

export const components = {
  silo: Silo,
  destroyer: Destroyer,
  ship: Destroyer,
  cutter: Cutter,
  drydock: DryDock,
  halfTank: HalfTank,
  destroyerFlightTwoA: DestroyerFlightTwoA,
}

export const gltfs = {
  silo: '/silo_shaders_1.glb',
  ship: '/destroyer_inspection.glb',
  cutter: '/cutter_with_plates.glb',
  destroyerFlightTwoA: '/low_poly_flight_two_a.glb',
  halfTank: '/half_tank.glb',
}

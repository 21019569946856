import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react'
import { Auth0Audience } from './helpers'
import { useEffect, useState } from 'react'
import { RolodexUrl } from './helpers'
import React from 'react'
import { useBoolean } from 'react-use'

const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
  return JSON.parse(jsonPayload)
}

const parseExtraTags = (parsedJwt: any) => {
  const perms: string[] = parsedJwt.permissions ?? []
  if (perms.find((perm) => ['gecko:read', 'gecko:rolodex:edit'].includes(perm))) {
    return {}
  }
  const perm = perms.find((perm) => perm.startsWith('rolodex:'))
  if (!perm) {
    console.log('no permissions found, expecting 403 errors from Rolodex')
    return {}
  }
  const parts = perm.split(':')
  if (parts.length != 4) {
    console.log('malformed permission found, expecting 403 errors from Rolodex: ' + perm)
    return {}
  }
  if (!['read', 'edit'].includes(parts[3])) {
    console.log('unknown operation, expecting 403 errors from Rolodex: ' + perm)
    return {}
  }
  return { [parts[1]]: parts[2] }
}

// TODO: add error handling
export const useRolodexEntry = async (
  type: string,
  link: string,
  filterType: string,
  filterKey: string,
  filterValue: string
) => {
  const [data, setData] = useState<any>(null)
  const { getAccessTokenSilently } = useAuth0()
  const [tokenString, setToken] = useState<string>('')
  const [extraTags, setExtraTags] = useState({})

  const getToken = async (
    getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>
  ) => {
    const token = await getAccessTokenSilently({
      authorizationParams: { audience: Auth0Audience() },
    })
    setToken(token)
    setExtraTags(parseExtraTags(parseJwt(token)))
  }

  useEffect(() => {
    getToken(getAccessTokenSilently).then()
    return () => {
      // unmount
    }
  }, [])

  useEffect(() => {
    if (tokenString !== '' && filterValue !== 'ddg-destroyer') {
      fetch(`${RolodexUrl()}/api/v2/entries/load`, {
        method: 'post',
        headers: {
          Authorization: `Bearer ${tokenString}`,
          'Content-Type': 'application/json',
        },

        //make sure to serialize your JSON body
        body: JSON.stringify({
          type: type,
          limit: 1000,
          links: [link],
          filters: [{ type: filterType, key: filterKey, value: filterValue }],
          tags: extraTags,
        }),
      })
        .then((data) => data.json())
        .then((data) => setData(data))
        .catch((error) => console.log(error))
    }
    //TODO: I hate this but within the time I had I had to do this to get the inspections mapped to a fake asset
    else if (tokenString !== '' && filterValue == 'ddg-destroyer') {
      fetch(`${RolodexUrl()}/api/v2/entries/load`, {
        method: 'post',
        headers: {
          Authorization: `Bearer ${tokenString}`,
          'Content-Type': 'application/json',
        },

        //make sure to serialize your JSON body
        body: JSON.stringify({
          type: type,
          filters: [{ key: 'shipview.demo_unit', value: 'ddg-destroyer' }],
          tags: extraTags,
        }),
      })
        .then((data) => data.json())
        .then((data) => setData(data))
        .catch((error) => console.log(error))
    } else {
    }
  }, [extraTags])

  return data
}

export const useRolodexEntryId = async (id: string) => {
  const [data, setData] = useState<any>(null)
  const { getAccessTokenSilently } = useAuth0()
  const [tokenString, setToken] = useState<string>('')
  const [extraTags, setExtraTags] = useState({})

  const getToken = async (
    getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>
  ) => {
    const token = await getAccessTokenSilently({
      authorizationParams: { audience: Auth0Audience() },
    })
    setToken(token)
    setExtraTags(parseExtraTags(parseJwt(token)))
  }

  useEffect(() => {
    getToken(getAccessTokenSilently).then()
    return () => {
      // unmount
    }
  }, [])

  useEffect(() => {
    if (tokenString !== '') {
      fetch(`${RolodexUrl()}/api/v2/entries/load`, {
        method: 'post',
        headers: {
          Authorization: `Bearer ${tokenString}`,
          'Content-Type': 'application/json',
        },

        //make sure to serialize your JSON body
        body: JSON.stringify({
          ids: [id],
          tags: extraTags,
        }),
      })
        .then((data) => data.json())
        .then((data) => setData(data))
        .catch((error) => console.log(error))
    } else {
    }
  }, [extraTags])
  return data
}

export const useRolodexEntrySite = async (unitId: string) => {
  const [siteData, setSiteData] = useState<any>(null)
  const [siteId, setSiteId] = useState<any>(null)
  const [unitData, setUnitData] = useState<any>(null)
  const { getAccessTokenSilently } = useAuth0()
  const [tokenString, setToken] = useState<string>('')
  const [extraTags, setExtraTags] = useState({})

  const getToken = async (
    getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>
  ) => {
    const token = await getAccessTokenSilently({
      authorizationParams: { audience: Auth0Audience() },
    })
    setToken(token)
    setExtraTags(parseExtraTags(parseJwt(token)))
  }

  useEffect(() => {
    getToken(getAccessTokenSilently).then()
    return () => {
      // unmount
    }
  }, [])

  useEffect(() => {
    if (tokenString !== '') {
      fetch(`${RolodexUrl()}/api/v2/entries/load`, {
        method: 'post',
        headers: {
          Authorization: `Bearer ${tokenString}`,
          'Content-Type': 'application/json',
        },

        //make sure to serialize your JSON body
        body: JSON.stringify({
          ids: [unitId],
          tags: extraTags,
        }),
      })
        .then((data) => data.json())
        .then((data) => setUnitData(data))
        .catch((error) => console.log(error))
    } else {
    }
  }, [extraTags])

  useEffect(() => {
    if (unitData !== null) {
      if (unitData.length > 0) {
        setSiteId(unitData[0].entry.tags.site)
      }
    }
  }, [unitData])

  useEffect(() => {
    if (siteId !== null) {
      fetch(`${RolodexUrl()}/api/v2/entries/load`, {
        method: 'post',
        headers: {
          Authorization: `Bearer ${tokenString}`,
          'Content-Type': 'application/json',
        },

        //make sure to serialize your JSON body
        body: JSON.stringify({
          ids: [siteId],
          tags: extraTags,
        }),
      })
        .then((data) => data.json())
        .then((data) => setSiteData(data))
        .catch((error) => console.log(error))
    }
  }, [siteId])

  return siteData
}

export const useRolodexImage = (entry_id: string, file_path: string) => {
  const [image, setImage] = useState<any>(null)
  const [imageURL, setImageURL] = React.useState<any>('')
  const { getAccessTokenSilently } = useAuth0()
  const [tokenString, setToken] = useState<string>('')

  const getToken = async (
    getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>
  ) => {
    const token = await getAccessTokenSilently({
      authorizationParams: { audience: Auth0Audience() },
    })
    setToken(token)
  }

  useEffect(() => {
    getToken(getAccessTokenSilently)
  }, [])

  useEffect(() => {
    if (tokenString !== '') {
      fetch(`${RolodexUrl()}/api/v2/files/${entry_id}/${file_path}`, {
        method: 'get',
        headers: {
          Authorization: `Bearer ${tokenString}`,
        },
      })
        .then((data: any) => data.blob())
        .then((data: any) => {
          const reader = new FileReader()
          reader.readAsText(data)
          reader.onloadend = () => {
            const base64data = reader.result
            setImageURL(base64data)
          }
        })
        .catch((error) => console.log(error))
    } else {
    }
  }, [tokenString])

  useEffect(() => {
    if (imageURL !== null) {
      let url = imageURL
      url = url.replace(/"/g, '') //drop all quotes from the string
      const substring = url.split('https://storage.googleapis.com/')[0] //drop everything before "https://storage.googleapis.com/"
      url = url.replace(substring, '')
      fetch(url)
        .then((data) => data.blob())
        .then((data) => {
          const fileReader = new FileReader()
          fileReader.readAsDataURL(data)
          fileReader.onloadend = () => {
            setImage(fileReader.result)
          }
        })
        .catch((error) => console.log(error))
    }
  }, [imageURL])

  if (image !== '') {
    return image
  }
}

export const useContainsRolodexFile = (entry_id: string, file_path: string) => {
  const [containsFile, setContainsFile] = React.useState(false)
  const [data, setData] = useState<any>(null)
  const { getAccessTokenSilently } = useAuth0()
  const [tokenString, setToken] = useState<string>('')

  useEffect(() => {
    if (data !== null && Array.isArray(data)) {
      setContainsFile(false)
      data.forEach((file: any) => {
        if (file.path === file_path) {
          setContainsFile(true)
        }
      })
    }
  }, [data])

  const getToken = async (
    getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>
  ) => {
    const token = await getAccessTokenSilently({
      authorizationParams: { audience: Auth0Audience() },
    })
    setToken(token)
  }

  useEffect(() => {
    getToken(getAccessTokenSilently)
  }, [])

  useEffect(() => {
    if (tokenString !== '') {
      fetch(`${RolodexUrl()}/api/v2/files/list`, {
        method: 'post',
        headers: {
          Authorization: `Bearer ${tokenString}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ entry_id: entry_id }),
      })
        .then((data) => data.json())
        .then((data) => setData(data))
        .catch((error) => console.log(error))
    } else {
    }
  }, [tokenString])

  return containsFile
}

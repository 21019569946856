import { RepairsDialogContent } from './RepairsDialogContent'
import { WastageDialogContent } from './WastageDialogContent'
import { ThicknessHeatmapDialogContent } from './ThicknessHeatmapDialogContent'

interface DialogContentSelectorProps {
  layerName: string
}

export default function DialogContentSelector(props: DialogContentSelectorProps) {
  const chooseDialogContent = (layerName: string) => {
    switch (layerName) {
      case 'repairs':
        return <RepairsDialogContent />
      case 'wastage':
        return <WastageDialogContent />
      case 'heatmap':
        return <ThicknessHeatmapDialogContent />
      default:
        return null
    }
  }

  return chooseDialogContent(props.layerName)
}

export function checkLayerName(layername: string) {
  const layerNames = ['repairs', 'wastage', 'heatmap']
  if (layerNames.includes(layername)) {
    return true
  } else {
    return false
  }
}

import { TitleText, DescriptionText, ImagesContainer, Image } from './styles'

export const WastageDialogContent = () => {
  return (
    <>
      <TitleText>
        <span style={{ color: 'grey' }}>Layer Description: </span>
        Excess Wastage Heatmap
      </TitleText>
      <DescriptionText>
        Gecko Robotics' excess wastage heatmap effectively visualizes excess wastage on
        metal assets by highlighting areas that exceed a user-defined threshold.
      </DescriptionText>
      <DescriptionText>
        By comparing the material thickness against this threshold, the heatmap enables
        asset managers to easily identify critical areas requiring maintenance or repair,
        leading to more informed and timely decision-making.
      </DescriptionText>
      <ImagesContainer>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Image
            style={{ transform: 'rotate(-90deg)', maxWidth: '38%' }}
            src='./wastage_example.png'></Image>
        </div>
      </ImagesContainer>
    </>
  )
}

import { Fragment, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import {
  rolodexUnitEntry,
  rolodexComponentEntry,
  rolodexInspectionEntry,
} from 'api/interfaces'
import { useRolodexImage } from 'api/useRolodex'
import React from 'react'
import {
  DossierDiv,
  DossierUnitTitleDiv,
  ShipLogo,
  ShipName,
  LineBreak,
  InspectionsOverviewDiv,
  InspectionsOverviewSpanContainer,
  InspectionOverviewSpan,
  CenterLineBreakDiv,
  LineBreakSmall,
  InspectionsOverflowDiv,
  FlightDeckInspectionDiv,
  UnitLabel
} from './style'
import { useSpring, animated } from '@react-spring/web'
import { Center } from '@react-three/drei'

export default function UnitSelector() {

  let navigate = useNavigate(); 
  const routeChange = (unit: string) => { 
    let path = unit; 
    navigate(path);
  }

  return (
    <DossierDiv as={animated.div} style={{ width: '40rem' }}>
      <InspectionsOverviewDiv>
        <InspectionsOverviewSpanContainer>
          <InspectionOverviewSpan>Select Unit</InspectionOverviewSpan>
        </InspectionsOverviewSpanContainer>
        <CenterLineBreakDiv>
          <LineBreakSmall />
        </CenterLineBreakDiv>
        <InspectionsOverflowDiv>
          <Fragment>
            <FlightDeckInspectionDiv
              onClick={() =>
                {routeChange('sentinel-class-cutter-306782')}
              }>
              <UnitLabel>
                USCG Richard Ethridge
              </UnitLabel>
            </FlightDeckInspectionDiv>
            <CenterLineBreakDiv>
              <LineBreakSmall />
            </CenterLineBreakDiv>
          </Fragment>
        </InspectionsOverflowDiv>
      </InspectionsOverviewDiv>
    </DossierDiv>
  )
}

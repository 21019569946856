import { ColorBarTitle, TopTick, BottomTick, RepairsColorBar } from './styles'

interface RepairsColorbarProps {}

export default function RepairsColorBarItem(props: RepairsColorbarProps) {
  return (
    <RepairsColorBar>
      <ColorBarTitle>Repairs</ColorBarTitle>
      <TopTick />
      <BottomTick />
    </RepairsColorBar>
  )
}

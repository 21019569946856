import { subtitleWidget } from 'api/interfaces'
import React from 'react'
import styled from 'styled-components'

const Subtitle = styled.h2`
  color: white;
  font-size: 1rem;
  font-weight: 500;
`

interface StatsSubtitleWidgetProps {
  subtitle: subtitleWidget
}
function StatsSubtitleWidget(props: StatsSubtitleWidgetProps) {
  return (
    <Subtitle key={props.subtitle?.key + '_' + props.subtitle?.value}>
      {' '}
      <span style={{ color: 'grey' }}>{props.subtitle?.key}</span> {props.subtitle?.value}
    </Subtitle>
  )
}

export default React.memo(StatsSubtitleWidget)

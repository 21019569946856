import { stat } from 'api/interfaces'
import React from 'react'
import styled from 'styled-components'
import { commas } from 'util/commas'

const GridValue = styled.p`
  text-align: center;
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.05rem;
`

const GridValueRed = styled.p`
  text-align: center;
  color: #bd655f;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.05rem;
`

const GridLabel = styled.p`
  text-align: center;
  color: grey;
  font-size: 0.8rem;
  font-weight: 500;
`
const StatsGridOne = styled.div`
  // display: grid;
  // grid-template-columns: repeat(1, 1fr);
  // grid-template-rows: repeat(autofill, 2, 1fr);
  // margin-left: auto;
  // margin-right: auto;
  // margin-top: 1rem;
  // grid-column-gap: 0.5rem;
  display: flex;
  justify-content: left;
  gap: 1rem;
  align-items: baseline;
`
const StatsGridItem = styled.div`
  display: flex;
  justify-content: center;
`

interface StatsStatsWidgetProps {
  stats: stat[]
}

function StatsStatsWidgetCollapsible(props: StatsStatsWidgetProps) {
  const renderStats = () => {
    let stat1
    switch (props.stats.length) {
      case 0:
        return <></>
      case 1:
        stat1 = props.stats.slice(-1)[0]
        return (
          <StatsGridOne>
            <StatsGridItem>
              <StatsGridValue
                value={stat1?.value}
                prefix={stat1?.prefix}
                suffix={stat1?.suffix}
                name={stat1?.name}
              />
            </StatsGridItem>
            <StatsGridItem>
              <GridLabel>{stat1?.description}</GridLabel>
            </StatsGridItem>
          </StatsGridOne>
        )
      default:
        stat1 = props.stats.slice(-1)[0]
        return (
          <StatsGridOne>
            <StatsGridItem>
              <StatsGridValue
                value={stat1?.value}
                prefix={stat1?.prefix}
                suffix={stat1?.suffix}
                name={stat1?.name}
              />
            </StatsGridItem>
            <StatsGridItem>
              <GridLabel>{stat1?.description}</GridLabel>
            </StatsGridItem>
          </StatsGridOne>
        )
    }
  }
  return renderStats()
}

interface valueProps {
  value: string | undefined
  prefix: string | undefined
  suffix: string | undefined
  name: string | undefined
}

function StatsGridValue(props: valueProps) {
  if (props.name === 'repair_area') {
    return (
      <GridValueRed>{commas(props?.value, props?.prefix, props?.suffix)}</GridValueRed>
    )
  } else {
    return <GridValue>{commas(props?.value, props?.prefix, props?.suffix)}</GridValue>
  }
}

export default React.memo(StatsStatsWidgetCollapsible)

import styled from 'styled-components'

export const TitleText = styled.h1`
  color: white;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
`

export const DescriptionText = styled.p`
  color: white;
  font-size: 15px;
  line-height: 1.5;
  margin-top: 10px;
`

export const ImagesContainer = styled.div`
  display: flex;
`

export const Image = styled.img`
  maxwidth: 100%;
`

import { layer } from 'api/interfaces'
import React from 'react'
import styled from 'styled-components'
import * as Dialog from '@radix-ui/react-dialog'
import { Cross2Icon } from '@radix-ui/react-icons'
import { RemainingLifeDialogContent } from './components/dialogComponents/RemainingLifeDialogContent'
import {
  DialogContent,
  DialogOverlay,
  IconButton,
  InfoIcon,
} from './StatsInspectionLayerToggles'

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const RemainingLifeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`

const Subtitle = styled.h2`
  color: white;
  font-size: 1rem;
  font-weight: 500;
`

const CustomCheckBoxHolder = styled.div`
  margin: 5px;
  display: flex;
`

const CustomCheckBoxInput = styled.input`
  display: none;
`

const CustomCheckBoxWrapper = styled.div`
  &first-of-type .customCheckBox {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-right: 0px;
  }

  &last-of-type .customCheckBox {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-left: 0px;
  }
`

const CustomCheckBox = styled.div<{ isChecked: boolean }>`
  width: fit-content;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  padding: 2px 8px;
  background-color: rgba(0, 0, 0, 0.16);
  border-radius: 0px;
  color: rgba(255, 255, 255, 0.7);
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 300ms;
  transition-property: color, background-color, box-shadow;
  display: flex;
  height: 32px;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 1px 0px inset,
    rgba(255, 255, 255, 0.17) 0px 1px 1px 0px;
  outline: none;
  justify-content: center;
  min-width: 55px;

  &:hover .inner {
    transform: translateY(-2px);
  }

  &:hover {
    background-color: #2c2c2c;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.23) 0px -4px 1px 0px inset,
      rgba(255, 255, 255, 0.17) 0px -1px 1px 0px, rgba(0, 0, 0, 0.17) 0px 2px 4px 1px;
  }

  ${CustomCheckBoxInput}:checked + & {
    background-color: #2d6737;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.23) 0px -4px 1px 0px inset,
      rgba(255, 255, 255, 0.17) 0px -1px 1px 0px, rgba(0, 0, 0, 0.17) 0px 2px 4px 1px;

    &:hover {
      background-color: #34723f;
      box-shadow: rgba(0, 0, 0, 0.26) 0px -4px 1px 0px inset,
        rgba(255, 255, 255, 0.17) 0px -1px 1px 0px, rgba(0, 0, 0, 0.15) 0px 3px 6px 2px;
    }
  }

  &:hover .inner {
    transform: translateY(-2px);
  }

  ${({ isChecked }) =>
    isChecked &&
    `
    background-color: white;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.23) 0px -4px 1px 0px inset,
      rgba(255, 255, 255, 0.17) 0px -1px 1px 0px, rgba(0, 0, 0, 0.17) 0px 2px 4px 1px;
    &:hover {
      background-color: white;
      color: black;
      box-shadow: rgba(0, 0, 0, 0.26) 0px -4px 1px 0px inset,
        rgba(255, 255, 255, 0.17) 0px -1px 1px 0px, rgba(0, 0, 0, 0.15) 0px 3px 6px 2px;
    }
  `}
`

const Inner = styled.div`
  font-size: 18px;
  font-weight: 900;
  pointer-events: none;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 300ms;
  transition-property: transform;
  transform: translateY(0px);
  font-family: Manrope;
  ${CustomCheckBoxInput}:checked + ${CustomCheckBoxWrapper}
    ${CustomCheckBox} & {
    transform: translateY(-2px);
  }
`

const PoppedOutInner = styled(Inner)<{ isChecked: boolean }>`
  transform: ${({ isChecked }) => (isChecked ? 'translateY(-2px)' : 'translateY(0px)')};
`

interface RemainingLifeCheckBoxWidgetProps {
  layers: layer[]
  inspectionId: string
  setInspectionLayers: (inspectionId: string, layerName: string) => void
  inspectionLayers: Map<string, Map<string, boolean>>
}

function RemainingLifeCheckBoxWidget(props: RemainingLifeCheckBoxWidgetProps) {
  const layer1 = props.layers[0]
  const layer2 = props.layers[1]
  const layer3 = props.layers[2]
  const layer4 = props.layers[3]

  const checkLayer = (layer: layer | undefined) => {
    if (!layer) {
      return false
    } else {
      const layerInMap = props.inspectionLayers.get(props.inspectionId)?.get(layer.name)
      if (layerInMap === true) {
        return true
      } else {
        return false
      }
    }
  }

  if (layer1 && layer2 && layer3 && layer4) {
    const isCheckedLayer1 = checkLayer(layer1)
    const isCheckedLayer2 = checkLayer(layer2)
    const isCheckedLayer3 = checkLayer(layer3)
    const isCheckedLayer4 = checkLayer(layer4)
    return (
      <RemainingLifeContainer>
        <TitleWrapper>
          <Subtitle>View Remaining Life Heatmap</Subtitle>
          <Dialog.Root>
            <Dialog.Trigger asChild id={`dialog-trigger-${layer1 ? layer1?.name : ''}`}>
              <InfoIcon
                className='InfoIcon'
                style={{ marginTop: 0, marginBottom: '0.5rem' }}
              />
            </Dialog.Trigger>
            <Dialog.Portal id={`dialog-portal-${layer1 ? layer1?.name : ''}`}>
              <DialogOverlay
                className='DialogOverlay'
                id={`dialog-overlay-${layer1 ? layer1?.name : ''}`}
              />
              <DialogContent
                className='DialogContent'
                id={`dialog-content-${layer1 ? layer1?.name : ''}`}>
                <RemainingLifeDialogContent />
                <Dialog.Close asChild id={`dialog-close-${layer1 ? layer1?.name : ''}`}>
                  <IconButton className='IconButton' aria-label='Close'>
                    <Cross2Icon />
                  </IconButton>
                </Dialog.Close>
              </DialogContent>
            </Dialog.Portal>
          </Dialog.Root>
        </TitleWrapper>
        <CustomCheckBoxHolder className='customCheckBoxHolder'>
          <CustomCheckBoxInput
            className='customCheckBoxInput'
            id='cCB1'
            type='checkbox'
          />
          <CustomCheckBoxWrapper
            className='customCheckBoxWrapper'
            onClick={() => {
              props.setInspectionLayers(props.inspectionId, layer1 ? layer1?.name : '')
            }}>
            <CustomCheckBox className='customCheckBox' isChecked={isCheckedLayer1}>
              <PoppedOutInner className='inner' isChecked={isCheckedLayer1}>
                {layer1.button_text ? layer1.button_text : ''}
              </PoppedOutInner>
            </CustomCheckBox>
          </CustomCheckBoxWrapper>

          <CustomCheckBoxInput
            className='customCheckBoxInput'
            id='cCB2'
            type='checkbox'
          />
          <CustomCheckBoxWrapper
            className='customCheckBoxWrapper'
            onClick={() => {
              props.setInspectionLayers(props.inspectionId, layer2 ? layer2?.name : '')
            }}>
            <CustomCheckBox className='customCheckBox' isChecked={isCheckedLayer2}>
              <PoppedOutInner className='inner' isChecked={isCheckedLayer2}>
                {layer2.button_text ? layer2.button_text : ''}
              </PoppedOutInner>
            </CustomCheckBox>
          </CustomCheckBoxWrapper>

          <CustomCheckBoxInput
            className='customCheckBoxInput'
            id='cCB3'
            type='checkbox'
          />
          <CustomCheckBoxWrapper
            className='customCheckBoxWrapper'
            onClick={() => {
              props.setInspectionLayers(props.inspectionId, layer3 ? layer3?.name : '')
            }}>
            <CustomCheckBox className='customCheckBox' isChecked={isCheckedLayer3}>
              <PoppedOutInner className='inner' isChecked={isCheckedLayer3}>
                {layer3.button_text ? layer3.button_text : ''}
              </PoppedOutInner>
            </CustomCheckBox>
          </CustomCheckBoxWrapper>
          <CustomCheckBoxInput
            className='customCheckBoxInput'
            id='cCB3'
            type='checkbox'
          />
          <CustomCheckBoxWrapper
            className='customCheckBoxWrapper'
            onClick={() => {
              props.setInspectionLayers(props.inspectionId, layer4 ? layer4?.name : '')
            }}>
            <CustomCheckBox className='customCheckBox' isChecked={isCheckedLayer4}>
              <PoppedOutInner className='inner' isChecked={isCheckedLayer4}>
                {layer4.button_text ? layer4.button_text : ''}
              </PoppedOutInner>
            </CustomCheckBox>
          </CustomCheckBoxWrapper>
        </CustomCheckBoxHolder>
      </RemainingLifeContainer>
    )
  } else {
    return <></>
  }
}

export default React.memo(RemainingLifeCheckBoxWidget)

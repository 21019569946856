import { rolodexComponentEntry, rolodexInspectionEntry } from './interfaces'
import Silo from '../components/models/Silo'
import Destroyer from '../components/models/Destroyer'
import DryDock from '../components/models/DryDock'

const { REACT_APP_ENVIRONMENT } = process.env

/*
DON'T PUT SENSITIVE VALUES IN THIS FILE
 */

const PROD_ENV = 'prod'

const AUTH0_CLIENT_ID_DEV = 'sYXwAmRGinmhCjCfh82r7eFMeQ8aaKc0'
const AUTH0_CLIENT_ID_PROD = 'ndG6ddKhtQ41Z9qJqFSPdcNLK1jE3gy0'

export const Auth0ClientId = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV ? AUTH0_CLIENT_ID_PROD : AUTH0_CLIENT_ID_DEV
}

const OBJECT_SERVICE_URL_DEV = 'https://object-service.dev.cloud.geckorobotics.com'
const OBJECT_SERVICE_URL_PROD = 'https://object-service.cloud.geckorobotics.com'

export const ObjectServiceUrl = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV
    ? OBJECT_SERVICE_URL_PROD
    : OBJECT_SERVICE_URL_DEV
}

export const RolodexUrl = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV
    ? 'https://rolodex.cloud.geckorobotics.com'
    : 'https://rolodex.dev.cloud.geckorobotics.com'
}

const AUTH0_AUDIENCE_DEV = 'dev.cloud.geckorobotics.com'
const AUTH0_AUDIENCE_PROD = 'cloud.geckorobotics.com'

export const Auth0Audience = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV ? AUTH0_AUDIENCE_PROD : AUTH0_AUDIENCE_DEV
}

export const getComponentName = (
  inspectionId: string,
  componentData: { entry: rolodexComponentEntry }[],
  inspectionData: { entry: rolodexInspectionEntry }[]
) => {
  //link all the component names to the inspection ids
  const componentNames = componentData.map((component) => {
    return {
      name: component.entry.data.portal.name,
      id: component.entry.id,
    }
  })
  //get the component ids from the inspection data
  const inspectionIds = inspectionData.map((inspection) => {
    return {
      id: inspection.entry.id,
      componentLink: inspection.entry.tags.component,
    }
  })

  // get the inspection that has id that matches the inspectionId
  const inspection = inspectionIds.find((inspection) => {
    return inspection.id === inspectionId
  })

  const componentId = inspection?.componentLink

  // get the component name that matches the component id
  const component = componentNames.find((component) => {
    return component.id === componentId
  })

  return component?.name
}

export const getInspection = (
  componentName: string,
  componentData: { entry: rolodexComponentEntry }[],
  inspectionData: { entry: rolodexInspectionEntry }[]
) => {
  // get component id from component name
  const component = componentData.find((component) => {
    return component.entry.data.portal.name === componentName
  })

  const componentId = component?.entry.id

  // get inspection with component tag as component id
  const inspection = inspectionData.find((inspection) => {
    return inspection.entry.tags.component === componentId
  })

  return inspection
}

export function IterateNested(obj: any, keys: string[]): string[] {
  //todo: inspect array elements in shipview, prefixes
  const keySet = new Set(keys)
  iterate(obj, keySet)
  return Array.from(keySet)
}

function iterate(obj: any, keys: Set<string>) {
  Object.keys(obj).forEach((key) => {
    // console.log(`key: ${key}, value: ${obj[key]}`)
    if (keys.has(key) && obj[key] !== null) {
      keys.delete(key)
    }
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      iterate(obj[key], keys)
    }
  })
}

//given number convert to string with commas
export function commas(
  num: string | number | undefined,
  prefix: string | undefined,
  suffix: string | undefined
): string {
  // if string can be converted to number
  if (!isNaN(Number(num))) {
    let value = Number(num)
    switch (suffix) {
      case 'M':
        return (
          prefix +
          (value / 1000000).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
          suffix
        )
      case 'K':
        return (
          prefix +
          (value / 1000).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
          suffix
        )

      case '%':
        return prefix + value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + suffix
      default:
        return (
          prefix + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' ' + suffix
        )
    }
  } else if (num === undefined || prefix === undefined || suffix === undefined) {
    return ''
  } else {
    return prefix + num + ' ' + suffix
  }
}

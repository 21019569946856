import { Canvas } from '@react-three/fiber'
import Experience from 'components/threefiber/Experience'
import { Loader, OrbitControls } from '@react-three/drei'
import Dossier from 'components/other/Dossier/Dossier'
import React, { Suspense, useCallback, useEffect } from 'react'
import TopBar from 'components/other/TopBar/TopBar'
import {
  rolodexComponentEntry,
  rolodexSiteEntry,
  rolodexUnitEntry,
  stat,
  rolodexInspectionEntry,
} from 'api/interfaces'
import LoadingContent from './LoadingContent'
import styled from 'styled-components'
import Stats from 'components/other/Stats/Stats'
import { Texture } from 'three'
import Staging from 'components/threefiber/Staging'

const StyledCanvas = styled(Canvas)`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    0deg,
    hsl(0deg 0% 20%) 0%,
    hsl(344deg 0% 19%) 25%,
    hsl(344deg 0% 18%) 42%,
    hsl(344deg 0% 16%) 54%,
    hsl(344deg 0% 15%) 63%,
    hsl(344deg 0% 14%) 70%,
    hsl(344deg 0% 13%) 76%,
    hsl(344deg 0% 12%) 81%,
    hsl(344deg 0% 10%) 86%,
    hsl(344deg 0% 9%) 91%,
    hsl(344deg 0% 8%) 95%,
    hsl(0deg 0% 7%) 100%
  );
`

const InfoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

interface dataProps {
  inspectionData: { entry: rolodexInspectionEntry }[] | null
  componentData: { entry: rolodexComponentEntry }[] | null
  unitData: { entry: rolodexUnitEntry }[] | null
  siteData: { entry: rolodexSiteEntry }[] | null
  inspectionLayers: Map<string, Map<string, boolean>>
  inspectionTextures: Map<string, Map<string, Texture>>
  stats: Map<string, Map<string, stat>>
  missingData: boolean
  missingSiteData: string[]
  missingUnitData: string[]
  missingComponentData: string[]
  missingInspectionData: string[]
}

export default function Layout(props: dataProps) {
  const siteData = props.siteData
  const unitData = props.unitData
  const componentData = props.componentData
  const inspectionData = props.inspectionData
  const [inspectionClicked, setInspectionClicked] = React.useState(false)
  const [zoomedInFinal, setZoomedInFinal] = React.useState(false)
  const [generateRepairs, setGenerateRepairs] = React.useState(false)
  const [generateWastage, setGenerateWastage] = React.useState(false)
  const [generateInspectionLayers, setGenerateInspectionLayers] = React.useState<
    Map<string, Map<string, boolean>>
  >(props.inspectionLayers)
  const inspectionTextures = props.inspectionTextures
  const [inspectionVisibility, setInspectionVisibility] = React.useState<boolean>(true)
  const [chosenComponent, setChosenComponent] = React.useState('')
  const [chosenInspectionData, setChosenInspectionData] =
    React.useState<rolodexInspectionEntry | null>(null)

  const missingData = props.missingData
  const missingSiteData = props.missingSiteData
  const missingUnitData = props.missingUnitData
  const missingComponentData = props.missingComponentData
  const missingInspectionData = props.missingInspectionData

  const reload = () => {
    setInspectionVisibility((prevVisibility) => !prevVisibility)
  }

  const setInspectionLayers = useCallback(
    (inspectionId: string, layerName: string) => {
      setGenerateInspectionLayers((prevState) => {
        const newInspectionLayers = new Map(prevState)

        if (!newInspectionLayers.has(inspectionId)) {
          newInspectionLayers.set(
            inspectionId,
            new Map<string, boolean>([[layerName, true]])
          )
        } else {
          const layers = Array.from(newInspectionLayers.get(inspectionId)!.keys())
          if (layerName.includes('rl')) {
            layers.forEach((layer) => {
              newInspectionLayers.get(inspectionId)!.set(layer, false)
            })
          } else {
            layers.forEach((layer) => {
              if (layer.includes('rl')) {
                newInspectionLayers.get(inspectionId)!.set(layer, false)
              }
            })
          }

          if (!newInspectionLayers.get(inspectionId)!.has(layerName)) {
            newInspectionLayers.get(inspectionId)!.set(layerName, true)
          } else {
            newInspectionLayers
              .get(inspectionId)!
              .set(layerName, !newInspectionLayers.get(inspectionId)!.get(layerName))
          }
        }

        return newInspectionLayers
      })

      reload()
    },
    [] // No dependencies
  )

  const populateHeatMapVisibility = (missingData: boolean) => {
    if (!missingData && inspectionData !== null) {
      for (let i = 0; i < inspectionData.length; i++) {
        let curMap = new Map<string, boolean>()
        const curTextures =
          inspectionData[i].entry.data.shipview.inspection_display.textures
        for (let j = 0; j < curTextures.length; j++) {
          curMap.set(curTextures[j].name, curTextures[j].visibility)
        }
        generateInspectionLayers.set(inspectionData[i].entry.id, curMap)
      }
    }
  }

  const clicked = useCallback((componentId: string) => {
    setChosenComponent(componentId)
    setInspectionClicked((prevInspectionClicked) => {
      if (prevInspectionClicked) {
        setZoomedIn(false)
        populateHeatMapVisibility(false)
      } else {
        setGenerateInspectionLayers(new Map<string, Map<string, boolean>>())
      }
      return !prevInspectionClicked
    })
  }, [])

  useEffect(() => {
    if (chosenComponent === '') {
    } else {
      if (componentData !== null && inspectionData !== null) {
        //loop through component data and find the component that matches the chosen component
        let componentId = ''
        for (let i = 0; i < componentData.length; i++) {
          if (componentData[i].entry.data.portal.name === chosenComponent) {
            componentId = componentData[i].entry.id
          }
        }
        //loop through inspection data and find the inspection that matches the chosen component
        for (let i = 0; i < inspectionData.length; i++) {
          if (inspectionData[i].entry.tags.component === componentId) {
            setChosenInspectionData(inspectionData[i].entry)
            setGenerateInspectionLayers(
              new Map<string, Map<string, boolean>>([
                [
                  inspectionData[i].entry.id,
                  new Map<string, boolean>([['heatmap', true]]),
                ],
              ])
            )
          }
        }
      }
    }
  }, [chosenComponent])

  const setZoomedIn = (bool: boolean) => {
    setZoomedInFinal(bool)
  }

  const getInspectionVisibility = () => {
    return inspectionVisibility
  }

  if (missingData === true) {
    return (
      <ContainerDiv>
        <h1>Missing Rolodex Site Data</h1>
        {missingSiteData.map((data) => {
          return (
            <p style={{ color: 'white' }} key={data}>
              {data}
            </p>
          )
        })}
        <h1>Missing Rolodex Unit Data</h1>
        {missingUnitData.map((data) => {
          return (
            <p style={{ color: 'white' }} key={data}>
              {data}
            </p>
          )
        })}
        <h1>Missing Rolodex Component Data</h1>
        {missingComponentData.map((data) => {
          return (
            <p style={{ color: 'white' }} key={data}>
              {data}
            </p>
          )
        })}

        <h1>Missing Rolodex Inspection Data</h1>
        {missingInspectionData.map((data) => {
          return (
            <p style={{ color: 'white' }} key={data}>
              {data}
            </p>
          )
        })}
      </ContainerDiv>
    )
  } else if (
    siteData === null ||
    unitData === null ||
    componentData === null ||
    inspectionData === null
    // inspectionTextures.size < 1
  ) {
    return <LoadingContent />
  } else {
    let inspectionAltered = false
    let inspectionDataAltered = inspectionData

    return (
      <ContainerDiv>
        <TopBar />
        <InfoContainer>
          <Dossier
            zoomedInFinal={zoomedInFinal}
            shipData={unitData}
            componentData={componentData}
            inspectionData={inspectionData}
            inspectionClicked={inspectionClicked}
            setInspectionClicked={clicked}
            chosenComponent={chosenComponent}
          />

          <StyledCanvas
            shadows
            camera={{
              position: unitData[0].entry.data.shipview.camera_default_pos,
              rotation: [0, 0, 0],
            }}>
            <Staging />
            <Suspense fallback={null}>
              <Experience
                zoomedInFinal={zoomedInFinal}
                setZoomedInFinal={setZoomedIn}
                inspectionClicked={inspectionClicked}
                setInspectionClicked={clicked}
                repairs={generateRepairs}
                wastage={generateWastage}
                inspectionLayers={generateInspectionLayers}
                inspectionTextures={props.inspectionTextures}
                inspectionVisibility={inspectionVisibility}
                inspectionData={
                  !inspectionAltered ? inspectionData : inspectionDataAltered
                }
                chosenInspectionData={chosenInspectionData}
                componentData={componentData}
                unitData={unitData}
                reload={reload}
              />
              {/* <OrbitControls /> */}
            </Suspense>
          </StyledCanvas>
          {zoomedInFinal === true ? (
            <Stats
              setInspectionClicked={clicked}
              siteData={siteData}
              unitData={unitData}
              componentData={componentData}
              chosenComponent={chosenComponent}
              inspectionData={inspectionData}
              setInspectionLayers={setInspectionLayers}
              getInspectionVisibility={getInspectionVisibility}
              inspectionClicked={inspectionClicked}
              inspectionLayers={generateInspectionLayers}
            />
          ) : null}
          <Loader />
        </InfoContainer>
      </ContainerDiv>
    )
  }
}

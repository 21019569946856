export const shipStatsConfig = [
  'Title',
  'Subtitle',
  'LineBreak',
  'CollapsibleStats',
  'LineBreak',
  'InspectionLayerButtons',
  'LineBreak',
  `RemainingLifeCheckBox`,
  'LineBreak',
  'PrimaryButton',
  'OperationalStatus',
]

export const siloStatsConfig = [
  'Title',
  'Subtitle',
  'LineBreak',
  'CollapsibleStats',
  'LineBreak',
  'InspectionLayerButtons',
  'LineBreak',
  `RemainingLifeCheckBox`,
  'LineBreak',
  'PrimaryButton',
  'OperationalStatus',
]

export const siloStatsConfigPrev = [
  'Title',
  'Subtitle',
  'Subtitle',
  'LineBreak',
  'CollapsibleStats',
  'Stats',
  'LineBreak',
  'InspectionLayerButtons',
  'LineBreak',
  'Stats',
  'LineBreak',
  'OperationalStatus',
  'PrimaryButton',
]

export const configSelector = (assetType: string) => {
  switch (assetType) {
    case 'ship':
      return shipStatsConfig
    case 'silo':
      return siloStatsConfig
    case 'halfTank':
      return shipStatsConfig
    case 'destroyerFlightTwoA':
      return shipStatsConfig
    case 'cutter':
      return shipStatsConfig
    default:
      return []
  }
}

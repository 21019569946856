import { stat } from 'api/interfaces'
import StatsStatsWidgetCollapsible from './StatsStatsWidgetCollapsible'
import { useEffect, useState } from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import styled, { keyframes } from 'styled-components'
import { ChevronDownIcon } from '@radix-ui/react-icons'
import React from 'react'

const open = keyframes`
from {
    height: 0;
}
to {
    height: var(--radix-accordion-content-height);
}
`

const close = keyframes`
from { 
  height: var(--radix-accordion-content-height); 
}
to { 
  height: 0; 
}
`

const AccordionRoot = styled(Accordion.Root)`
  margin-top: 1rem;
  cursor: pointer;
  border-radius: 6px;
  width: 300px;
  background-color: rgb(11, 33, 45);
  // box-shadow: 0 2px 10px black;
`

const AccordionItem = styled(Accordion.Item)`
  cursor: pointer;

  overflow: hidden;
  margin-top: 1px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
  }

  &:focus-within {
    position: relative;
    z-index: 1;
  }
`

const AccordionHeader = styled(Accordion.Header)`
  cursor: pointer;

  display: flex;
`

const AccordionChevron = styled(ChevronDownIcon)`
  transition: transform 300ms ease-in-out;
  [data-state='open'] & {
    transform: rotate(180deg);
  }

  // make bigger
  width: 2rem;
  height: 2rem;
`

const AccordionTrigger = styled(Accordion.Trigger)`
  font-family: inherit;
  background-color: transparent;
  padding: 0 20px;
  height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 1.25rem;
  line-height: 1;
  color: white;
  box-shadow: 0 1px 0 rgb(11, 33, 45);
  background-color: rgb(11, 33, 45);
`

const AccordionContent = styled(Accordion.Content)`
  overflow: hidden;
  font-size: 15px;
  color: white;
  background-color: rgb(11, 33, 45);

  &[data-state='open'] {
    animation: ${open} 300ms cubic-bezier(0.87, 0, 0.13, 1);
    background-color: ${({ theme }) => theme.colors.slate[900]};
  }
  &[data-state='closed'] {
    background-color: ${({ theme }) => theme.colors.slate[900]};
    animation: ${close} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`

const AccordionContentText = styled.div`
  padding: 15px 20px;
`

interface StatsTitleWidgetProps {
  statsArray: stat[]
}
function StatsCollapsibleWidget(props: StatsTitleWidgetProps) {
  const [renderedStats, setRenderedStats] = useState<JSX.Element[]>([])

  useEffect(() => {
    setRenderedStats(renderStats())
  }, [props.statsArray])

  const renderStats = () => {
    return props.statsArray.map((stat, i) => {
      return <StatsStatsWidgetCollapsible stats={[stat]} key={i} />
    })
  }

  const renderContent = () => {
    if (props.statsArray.length > 0 && renderedStats) {
      return (
        <AccordionRoot type='single' collapsible={true}>
          <AccordionItem value='item-1'>
            <AccordionHeader>
              <AccordionTrigger>
                View Inspection Stats
                <AccordionChevron aria-hidden />
              </AccordionTrigger>
            </AccordionHeader>
            <AccordionContent>
              <AccordionContentText>{renderedStats}</AccordionContentText>
            </AccordionContent>
          </AccordionItem>
        </AccordionRoot>
      )
    } else {
      return <></>
    }
  }

  return <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>{renderContent()}</div>
}

export default React.memo(StatsCollapsibleWidget)

import styled from 'styled-components'

export const TopBarComp = styled.div`
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background: #000000;
`

export const Title = styled.h2`
  margin: 1rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 700;
  text-align: center;
  text-overflow: ellipsis;
  max-width: 100%;
  font-family: Manrope;
  letter-spacing: 2px;
  margin-right: auto;
`

export const Logo = styled.img`
  max-width: 100%;
  height: 90%;
  padding-left: 0.75rem;
`

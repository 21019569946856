import {
  rolodexSiteEntry,
  rolodexUnitEntry,
  rolodexComponentEntry,
  rolodexInspectionEntry,
  titleWidget,
  subtitleWidget,
  stat,
  layer,
  primaryButtonWidget,
} from 'api/interfaces'
import { Fragment, useEffect, useState } from 'react'
import {
  getTitles,
  getSubtitles,
  getStats,
  getOperationalStatus,
  getPrimaryButtons,
  getInspectionLayers,
} from 'util/widgetHelpers'
import { configSelector } from './configs/configs'
import StatsTitleWidget from './StatsTitleWidget'
import StatsSubtitleWidget from './StatsSubtitleWidget'
import StatsStatsWidget from './StatsStatsWidget'
import StatsOperationalStatusWidget from './StatsOperationalStatusWidget'
import StatsLineBreakWidget from './StatsLineBreakWidget'
import PrimaryButtonWidget from './PrimaryButton'
import RemainingLifeCheckBoxWidget from './RemainingLifeCheckBox'
import StatsCollapsibleWidget from './CollapsibleStats'
import styled from 'styled-components'
import StatsLayersTogglesWidget from './StatsInspectionLayerToggles'

const BottomPadding = styled.div`
  padding-bottom: 5rem;
`

interface StatsWidgetProps {
  setInspectionClicked: (componentId: string) => void
  siteData: { entry: rolodexSiteEntry }
  unitData: { entry: rolodexUnitEntry }
  chosenComponent: string
  inspectionData: { entry: rolodexInspectionEntry }
  setInspectionLayers: (inspectionId: string, layerName: string) => void
  getInspectionVisibility: () => boolean
  inspectionLayers: Map<string, Map<string, boolean>>
}

export default function StatsWidget(props: StatsWidgetProps) {
  const [inspectionLayers, setInspectionLayers] = useState<layer[]>([])
  const [remainingLifeLayers, setRemainingLifeLayers] = useState<layer[]>([])
  const [widgetData, setWidgetData] = useState<{
    titles: titleWidget[]
    subtitles: subtitleWidget[]
    stats: stat[]
    operationalStatus: string
    primaryButtons: primaryButtonWidget[]
    config: string[] | null
  }>({
    titles: [],
    subtitles: [],
    stats: [],
    operationalStatus: '',
    primaryButtons: [],
    config: null,
  })

  useEffect(() => {
    setWidgetData({
      titles: getTitles(props.inspectionData),
      subtitles: getSubtitles(props.inspectionData),
      stats: getStats(props.inspectionData),
      operationalStatus: getOperationalStatus(props.unitData),
      primaryButtons: getPrimaryButtons(props.inspectionData),
      config: configSelector(props.inspectionData.entry.data.shipview.asset_type),
    })
  }, [props.getInspectionVisibility])

  useEffect(() => {
    const inspectionLayers: layer[] = []
    const remainingLifeLayers: layer[] = []
    getInspectionLayers(props.inspectionData).forEach((layer) => {
      if (layer.name.includes('rl')) {
        remainingLifeLayers.push(layer)
      } else {
        inspectionLayers.push(layer)
      }
    })
    setInspectionLayers(inspectionLayers)
    setRemainingLifeLayers(remainingLifeLayers)
  }, [props.getInspectionVisibility])

  if (widgetData.config === null) return <></>
  else {
    let prevWidget = ''
    return (
      <>
        {widgetData.config.map((widget, i) => {
          switch (widget) {
            case 'CollapsibleStats':
              prevWidget = 'CollapsibleStats'
              return <StatsCollapsibleWidget statsArray={widgetData.stats} key={i} />
            case 'Title':
              if (widgetData.titles.length === 0) {
                return <Fragment key={i}></Fragment>
              } else {
                prevWidget = 'Title'
                return widgetData.titles.map((title, i) => {
                  return <StatsTitleWidget title={title} key={i} />
                })
              }
            case 'Subtitle':
              if (widgetData.subtitles.length === 0) {
                return <Fragment key={i}></Fragment>
              } else {
                prevWidget = 'Subtitle'
                return widgetData.subtitles.map((subtitle, i) => {
                  return <StatsSubtitleWidget subtitle={subtitle} key={i} />
                })
              }
            case 'Stats':
              if (widgetData.stats.length === 0) {
                return <Fragment key={i}></Fragment>
              } else if (widgetData.stats.length === 1) {
                prevWidget = 'Stats'
                const stats1: stat = widgetData.stats.pop()!
                return <StatsStatsWidget stats={[stats1]} key={i} />
              } else if (widgetData.stats.length === 2) {
                prevWidget = 'Stats'
                const stats1: stat = widgetData.stats.pop()!
                const stats2: stat = widgetData.stats.pop()!
                return <StatsStatsWidget stats={[stats1, stats2]} key={i} />
              } else if (widgetData.stats.length === 3) {
                prevWidget = 'Stats'
                const stats1: stat = widgetData.stats.pop()!
                const stats2: stat = widgetData.stats.pop()!
                const stats3: stat = widgetData.stats.pop()!
                return <StatsStatsWidget stats={[stats1, stats2, stats3]} key={i} />
              } else {
                prevWidget = 'Stats'
                const stats1: stat = widgetData.stats.pop()!
                const stats2: stat = widgetData.stats.pop()!
                const stats3: stat = widgetData.stats.pop()!
                return <StatsStatsWidget stats={[stats1, stats2, stats3]} key={i} />
              }
            case 'OperationalStatus':
              if (widgetData.operationalStatus === null || widgetData.operationalStatus === 'NA') {
                return <Fragment key={i}></Fragment>
              } else {
                prevWidget = 'OperationalStatus'
                return (
                  <StatsOperationalStatusWidget
                    operationalStatus={widgetData.operationalStatus}
                    key={i}
                  />
                )
              }
            case 'LineBreak':
              if (prevWidget === 'LineBreak') {
                return <Fragment key={i}></Fragment>
              } else {
                prevWidget = 'LineBreak'
                return <StatsLineBreakWidget key={i} />
              }
            case 'PrimaryButton':
              if (widgetData.primaryButtons.length === 0) {
                return <Fragment key={i}></Fragment>
              } else {
                prevWidget = 'PrimaryButton'
                return widgetData.primaryButtons.map((primaryButton, i) => {
                  return <PrimaryButtonWidget primaryButton={primaryButton} key={i} />
                })
              }
            case 'InspectionLayerButtons':
              if (inspectionLayers.length === 0) {
                return <Fragment key={i}></Fragment>
              } else if (
                inspectionLayers[inspectionLayers.length - 1]!.name.includes('rl')
              ) {
                prevWidget = 'InspectionLayerButtons'
                return <Fragment key={i}></Fragment>
              } else {
                prevWidget = 'InspectionLayerButtons'
                return inspectionLayers.map((inspectionLayer, i) => {
                  return (
                    <StatsLayersTogglesWidget
                      layers={[inspectionLayer]}
                      inspectionId={props.inspectionData.entry.id}
                      setInspectionLayers={props.setInspectionLayers}
                      key={i}
                      inspectionLayers={props.inspectionLayers}
                    />
                  )
                })
              }

            case 'RemainingLifeCheckBox':
              if (remainingLifeLayers.length !== 4) {
                return <Fragment key={i}></Fragment>
              } else {
                prevWidget = 'RemainingLifeCheckBox'
                const inspectionLayer1: layer = remainingLifeLayers.slice(-4)[3]!
                const inspectionLayer2: layer = remainingLifeLayers.slice(-4)[2]!
                const inspectionLayer3: layer = remainingLifeLayers.slice(-4)[1]!
                const inspectionLayer4: layer = remainingLifeLayers.slice(-4)[0]!

                return (
                  <RemainingLifeCheckBoxWidget
                    layers={[
                      inspectionLayer1,
                      inspectionLayer2,
                      inspectionLayer3,
                      inspectionLayer4,
                    ]}
                    inspectionId={props.inspectionData.entry.id}
                    setInspectionLayers={props.setInspectionLayers}
                    key={i}
                    inspectionLayers={props.inspectionLayers}
                  />
                )
              }
          }
        })}
        <BottomPadding />
      </>
    )
  }
}

import React from 'react'
import styled from 'styled-components'

const LineBreak = styled.hr`
  margin-right: auto;
  margin-left: auto;
  margin-top: 1rem;
  width: 90%;
  border-color: ;
  border-radius: 1rem;
  border 0.1rem solid rgb(40, 61, 77);
`

function StatsLineBreakWidget() {
  return <LineBreak />
}

export default React.memo(StatsLineBreakWidget)

import { DescriptionText, ImagesContainer, Image, TitleText } from './styles'

export const RemainingLifeDialogContent = () => {
  return (
    <>
      <TitleText>
        <span style={{ color: 'grey' }}>Layer Description: </span>
        Remaining Life
      </TitleText>
      <DescriptionText>
        The remaining life layer is a predictive layer that estimates future percentage
        wastage on an asset by calculating the observed linear corrosion rate between two
        given dates. This layer displays a heatmap, where red areas indicate parts of the
        asset that are projected to have excess wastage beyond a user-defined threshold at
        a specified future date.
      </DescriptionText>
      <DescriptionText>
        By using historical corrosion data, the remaining life layer helps asset managers
        proactively identify areas at risk of excessive degradation. This enables them to
        prioritize maintenance and repair activities, ultimately enhancing asset
        reliability and longevity.
      </DescriptionText>
      <ImagesContainer>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Image
            style={{ transform: 'rotate(-90deg)', maxWidth: '38%' }}
            src='./remaining_life_example.png'></Image>
        </div>
      </ImagesContainer>
    </>
  )
}

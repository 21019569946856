import { ButtonSVG } from '../Dossier/style'
import {
  rolodexSiteEntry,
  rolodexUnitEntry,
  rolodexComponentEntry,
  rolodexInspectionEntry,
} from 'api/interfaces'
import { getInspection } from 'api/helpers'
import StatsWidget from 'widgets/Stats/StatsWidget'
import { useSpring, animated } from '@react-spring/web'
import { ContentContainer, MarginContainer, StatsDiv, TopBar } from './style'
import React from 'react'
import Colorbar from 'widgets/Stats/Colorbar'
import { getInspectionLayers } from 'util/widgetHelpers'

interface StatsProps {
  setInspectionClicked: (componentId: string) => void
  setInspectionLayers: (inspectionId: string, layerName: string) => void
  siteData: { entry: rolodexSiteEntry }[]
  unitData: { entry: rolodexUnitEntry }[]
  chosenComponent: string
  componentData: { entry: rolodexComponentEntry }[]
  inspectionData: { entry: rolodexInspectionEntry }[]
  inspectionClicked: boolean
  getInspectionVisibility: () => boolean
  inspectionLayers: Map<string, Map<string, boolean>>
}

function Stats(props: StatsProps) {
  const { width }: any = useSpring({
    from: { width: '0' },
    width: props.inspectionClicked ? '40rem' : '0',
  })
  const inspection = getInspection(
    props.chosenComponent,
    props.componentData,
    props.inspectionData
  )

  if (inspection === undefined) {
    return null
  } else {
    const inspectionLayers = getInspectionLayers(inspection)
    return (
      <>
        <Colorbar
          layers={inspectionLayers}
          inspectionId={inspection.entry.id}
          inspectionLayers={props.inspectionLayers}
          inspectionClicked={props.inspectionClicked}
        />
        <StatsDiv as={animated.div} style={{ width: width }}>
          <TopBar>
            <ButtonSVG
              onClick={() => props.setInspectionClicked('')}
              xmlns='http://www.w3.org/2000/svg'
              width='23.707'
              height='18.594'
              viewBox='0 0 23.707 18.594'
              style={{ margin: '0 1rem' }}>
              <path
                id='back'
                d='M32.442,27.779H18.257l3.013-3.013a.692.692,0,1,0-.979-.979L16.081,27.98a.692.692,0,0,0-.086.1v.028a.692.692,0,0,0-.048.09v.042a.592.592,0,0,0,0,.446v.042a.692.692,0,0,0,.048.09v.028a.692.692,0,0,0,.086.1l4.211,4.218a.692.692,0,0,0,.979-.979L18.24,29.163H32.425a5.667,5.667,0,0,1,0,11.331H16.572a.692.692,0,1,0,0,1.384h15.87a7.051,7.051,0,0,0,0-14.1Z'
                transform='translate(-15.755 -23.409)'
                fill='#7f949c'
              />
            </ButtonSVG>
          </TopBar>
          <ContentContainer>
            <MarginContainer>
              <StatsWidget
                setInspectionClicked={props.setInspectionClicked}
                siteData={props.siteData[0]}
                unitData={props.unitData[0]}
                chosenComponent={props.chosenComponent}
                inspectionData={inspection}
                setInspectionLayers={props.setInspectionLayers}
                getInspectionVisibility={props.getInspectionVisibility}
                inspectionLayers={props.inspectionLayers}
              />
            </MarginContainer>
          </ContentContainer>
        </StatsDiv>
      </>
    )
  }
}
export default React.memo(Stats)

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Silo(props) {
  const { nodes, materials } = useGLTF('/silo_shaders_1.glb')

  //const white = '0x8c8480'
  //const white = '0xcccccc'
  const white = '0x969695'

  const chamber_mat = materials.Chamber_Concrete
  chamber_mat.opacity = 1
  chamber_mat.transparent = false
  chamber_mat.color.set(parseInt(white))

  const base_mat = materials.Base_Concrete
  base_mat.opacity = 1
  base_mat.transparent = false
  base_mat.color.set(parseInt(white))

  const sleeve_mat = materials.Sleeve_Concrete
  sleeve_mat.opacity = 1
  sleeve_mat.transparent = false
  sleeve_mat.color.set(parseInt(white))

  const lid_mat = materials.Lid_Concrete
  lid_mat.opacity = 1
  lid_mat.transparent = false
  lid_mat.color.set(parseInt(white))

  const rocket_mat = materials.MLRS_Rocket_Mat
  rocket_mat.opacity = 1
  rocket_mat.transparent = false

  const liner_mat = materials.Liner_Steel
  liner_mat.opacity = 1
  liner_mat.transparent = false

  // console.log(nodes.Chamber.position)
  //
  // const boundingBox = new THREE.Box3()
  // const object = nodes['Chamber']
  // boundingBox.setFromObject(object)
  //
  // let center = new Vector3()
  // let size = new Vector3()
  // center = boundingBox.getCenter(center)
  // size = boundingBox.getSize(size)
  //
  // console.log(center)
  // console.log(size)

  return (
    <group {...props} dispose={null} rotation={[0, 0, 0]} scale={1}>
        <group rotation={nodes.Base.rotation} position={nodes.Base.position}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Base.geometry}
            material={base_mat}
          />
        </group>
        <group rotation={nodes.Chamber.rotation} position={nodes.Chamber.position}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Chamber.geometry}
            material={chamber_mat}
          />
        </group>
        <group rotation={nodes.Sleeve.rotation} position={nodes.Sleeve.position}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Sleeve.geometry}
            material={sleeve_mat}
          />
        </group>
        <group rotation={nodes.Lid.rotation} position={nodes.Lid.position}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Lid.geometry}
            material={lid_mat}
          />
        </group>
        <group rotation={nodes.Liner.rotation} position={nodes.Liner.position}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Liner.geometry}
            material={liner_mat}
          />
        </group>
        <group
          rotation={nodes.MLRS_Rocket.rotation}
          position={nodes.MLRS_Rocket.position}
          scale={nodes.MLRS_Rocket.scale}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.MLRS_Rocket.geometry}
            material={rocket_mat}
          />
        </group>
      </group>
  )
}

useGLTF.preload('/silo_shaders_1.glb')

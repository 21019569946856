import { layer } from 'api/interfaces'
import {
  ColorBarTitle,
  HeatMapColorBar,
  TopTick,
  BottomTick,
  BottomUnitText,
  TopUnitText,
} from './styles'

interface HeatmapColorbarProps {
  layer: layer
}

export default function HeatMapColorBarItem(props: HeatmapColorbarProps) {
  const nominal_bound = () => {
    if (props.layer.nominal_colorbar_threshold === undefined) {
      return null
    } else {
      return <TopUnitText>{props.layer.nominal_colorbar_threshold}%</TopUnitText>
    }
  }

  const tmin_bound = () => {
    if (props.layer.tmin_colorbar_threshold === undefined) {
      return null
    } else {
      return <BottomUnitText>{props.layer.tmin_colorbar_threshold}%</BottomUnitText>
    }
  }

  return (
    <HeatMapColorBar>
      <ColorBarTitle>Heat Map</ColorBarTitle>
      <TopTick />
      <BottomTick />
      {nominal_bound()}
      {tmin_bound()}
    </HeatMapColorBar>
  )
}

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: Yi Tsung Lee (https://sketchfab.com/WTigerTw)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/11-low-poly-us-navy-ddg-51-uss-arleigh-burke-17be09c31c6047e4a5969b68c29eba03
title: 1:1 Low poly US NAVY DDG-51 USS Arleigh Burke.
*/

import { useGLTF } from '@react-three/drei'
import { Decal, useTexture } from '@react-three/drei'
import { useGraph } from '@react-three/fiber'
import * as THREE from 'three'

interface DestroyerHullDemoProps {
  repairs: boolean
  wastage: boolean
}

export default function DestroyerHullDemo(props: DestroyerHullDemoProps) {
  const gltf = useGLTF('/destroyer.glb')
  const { nodes, materials } = useGraph(gltf.scene)
  const heatmapTexture = useTexture('heatmap_hull.png')
  const wastageTexture = useTexture('wastage_hull.png')
  const repairBoxesTexture = useTexture('repairs_hull.png')

  const position: any = [0, 30, -12]
  const rotation: any = [0, -1.57, 1.57]
  const scale = 130

  const setDecals = () => {
    if (props.repairs === true && props.wastage === true) {
      return (
        <Decal
          map={wastageTexture}
          rotation={rotation}
          scale={scale}
          position={position}></Decal>
      )
    } else if (props.repairs === true && props.wastage === false) {
      return (
        <>
          <Decal
            //   debug
            map={heatmapTexture}
            rotation={rotation}
            scale={scale}
            position={position}></Decal>
          <Decal
            //   debug
            map={repairBoxesTexture}
            rotation={rotation}
            scale={scale}
            position={position}></Decal>
        </>
      )
    } else if (props.repairs === false && props.wastage === true) {
      return (
        <Decal
          //   debug
          map={wastageTexture}
          rotation={rotation}
          scale={scale}
          position={position}></Decal>
      )
    } else {
      return (
        <Decal
          //   debug
          map={heatmapTexture}
          rotation={rotation}
          scale={scale}
          position={position}></Decal>
      )
    }
  }

  return (
    <group
      {...props}
      dispose={null}
      rotation={[0, -1.5, 0]}
      position={[0, 0, 0]}
      scale={0.075}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              castShadow
              receiveShadow
              geometry={(nodes.Object_4 as THREE.Mesh).geometry}
              material={materials.CIWS}
            />
          </group>
          <group rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              castShadow
              receiveShadow
              geometry={(nodes.Object_6 as THREE.Mesh).geometry}
              material={materials.DDG112}>
              {setDecals()}
            </mesh>
          </group>
          <group rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              castShadow
              receiveShadow
              geometry={(nodes.Object_8 as THREE.Mesh).geometry}
              material={materials.mk38}
            />
          </group>
          <group rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              castShadow
              receiveShadow
              geometry={(nodes.Object_10 as THREE.Mesh).geometry}
              material={materials.DDG112}
            />
          </group>
          <group rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              castShadow
              receiveShadow
              geometry={(nodes.Object_12 as THREE.Mesh).geometry}
              material={materials['RHIB.001']}
            />
          </group>
          <group rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              castShadow
              receiveShadow
              geometry={(nodes.Object_14 as THREE.Mesh).geometry}
              material={materials.spg62}
            />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/destroyer.glb')

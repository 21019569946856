import { layer } from 'api/interfaces'
import HeatMapColorBarItem from './HeatmapColorbar'
import RepairsColorBarItem from './RepairsColorbar'
import WastageColorBarItem from './WastageColorbar'

interface DialogContentSelectorProps {
  layerName: string
  layer: layer
}

export default function DialogContentSelector(props: DialogContentSelectorProps) {
  const chooseDialogContent = (layerName: string, layer: layer) => {
    switch (layerName) {
      case 'repairs':
        return <RepairsColorBarItem />
      case 'wastage':
        return <WastageColorBarItem />
      case 'heatmap':
        return <HeatMapColorBarItem layer={layer} />
      default:
        if (layerName.includes('rl')) {
          return <WastageColorBarItem />
        } else {
          return null
        }
    }
  }

  return chooseDialogContent(props.layerName, props.layer)
}

import styled from 'styled-components'

export const StatsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 40rem;
  background: rgb(17, 17, 17);
`

export const TopBar = styled.div`
  display: flex;
  height: 3rem;
  width: 100%;
  background: rgb(17, 17, 17);
s  align-items: center;
`

export const ContentContainer = styled.div`
  width: 80%;
  height: calc(100% - 3rem);
  overflow-y: auto;
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 1.25rem;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(128, 128, 128);
    border-radius: 20px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgb(128, 128, 128);
  }
`
export const MarginContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`

import { TitleText, DescriptionText, ImagesContainer, Image } from './styles'

export const ThicknessHeatmapDialogContent = () => {
  return (
    <>
      <TitleText>
        <span style={{ color: 'grey' }}>Layer Description: </span>
        Percentage Wastage Heatmap
      </TitleText>
      <DescriptionText>
        The percentage wastage heatmap utilizes ultrasonic thickness data collected
        through rapid ultrasonic gridding and one of Gecko Robotics' Toka series robots.
      </DescriptionText>
      <DescriptionText>
        Toka is an advanced inspection robot designed to crawl on metal assets and
        generate precise thickness readings. Utilizing an array of immersion straight beam
        ultrasonic transducers, the robot accurately measures the thickness of the asset's
        material. If nominal thickness values are available, they are used as a reference
        to calculate the percentage deviation in thickness or wastage. In the absence of
        such data, the system predicts the nominal thickness using advanced algorithms.
      </DescriptionText>
      <DescriptionText>
        A percentage wastage heatmap provides a clear visualization of material
        degradation across a range of different nominal thicknesses, facilitating
        efficient maintenance and asset management.
      </DescriptionText>
      <ImagesContainer>
        <div>
          <Image src='./heatmap_example.png'></Image>
        </div>
        <div>
          <Image src='./toka4.png'></Image>
        </div>
      </ImagesContainer>
    </>
  )
}

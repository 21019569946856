import {
  titleWidget,
  subtitleWidget,
  stat,
  layer,
  operationalStatusWidget,
  rolodexInspectionEntry,
  primaryButtonWidget,
  rolodexUnitEntry,
} from 'api/interfaces'

export const getTitles = (inspectionData: { entry: rolodexInspectionEntry }) => {
  //loop through inspectionData.entry.data.shipview.text_metadata
  // and find all objects where widget_type === 'Title'
  // and return list of these objects
  const titles: titleWidget[] = []
  inspectionData.entry.data.shipview.text_metadata.forEach((item) => {
    if (item.widget_type === 'Title') {
      titles.push(item as titleWidget)
    }
  })
  return titles
}

export const getSubtitles = (inspectionData: { entry: rolodexInspectionEntry }) => {
  //loop through inspectionData.entry.data.shipview.text_metadata
  // and find all objects where widget_type === 'Subtitle'
  // and return list of these objects
  const subtitles: subtitleWidget[] = []
  inspectionData.entry.data.shipview.text_metadata.forEach((item) => {
    if (item.widget_type === 'Subtitle') {
      subtitles.push(item as subtitleWidget)
    }
  })
  return subtitles
}

export const getStats = (inspectionData: { entry: rolodexInspectionEntry }) => {
  //loop through inspectionData.entry.data.shipview.stats
  // and return list of these objects

  const stats: stat[] = []

  if (inspectionData.entry.data.shipview.stats.length === 0) {
    return stats
  } else {
    inspectionData.entry.data.shipview.stats.forEach((item) => {
      stats.push(item)
    })

    return stats
  }
}

export const getOperationalStatus = (unitData: { entry: rolodexUnitEntry }) => {
  let operationalStatus: string

  if (unitData.entry.data.hasOwnProperty('service_on_time')) {
    operationalStatus = unitData.entry.data.service_on_time!.toUpperCase()
  } else if (unitData.entry.data.hasOwnProperty('asset_health')) {
    const inputStr = unitData.entry.data.asset_health!
    const capitalizedStr = inputStr.charAt(0).toUpperCase() + inputStr.slice(1)
    operationalStatus = capitalizedStr
  } else {
    operationalStatus = 'NA'
  }

  return operationalStatus
}

export const getPrimaryButtons = (inspectionData: { entry: rolodexInspectionEntry }) => {
  //loop through inspectionData.entry.data.shipview.text_metadata
  // and find all objects where widget_type === 'Primary Button'
  // and return list of these objects
  const primaryButtons: primaryButtonWidget[] = []
  inspectionData.entry.data.shipview.text_metadata.forEach((item) => {
    if (item.widget_type === 'Primary Button') {
      primaryButtons.push(item as primaryButtonWidget)
    }
  })
  return primaryButtons
}

export const getInspectionLayers = (inspectionData: {
  entry: rolodexInspectionEntry
}) => {
  //loop through inspectionData.entry.data.shipview.stats
  // and return list of these objects

  const layers: layer[] = []

  if (inspectionData.entry.data.shipview.inspection_display.textures.length === 0) {
    return layers
  } else {
    inspectionData.entry.data.shipview.inspection_display.textures.forEach((item) => {
      layers.push(item)
    })

    return layers
  }
}

import { useGLTF } from '@react-three/drei'

export default function DestroyerFlightTwoA(props) {
  const { nodes, materials } = useGLTF('/low_poly_flight_two_a.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[-0.05, -0.22, 0.9]}>
        {/* <mesh
          castShadow
          receiveShadow
          geometry={nodes.flight_deck.geometry}
          material={materials['Material.001']}
          position={[-0.04, 80.1, -0.7]}
        /> */}
        <group position={[-0.04, 0, -0.7]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Rail_1.geometry}
            material={materials['Rail.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Rail_2.geometry}
            material={materials.Rail2}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Screw1.geometry}
          material={materials['ddg87-1.002']}
          position={[-2.64, -23.92, 65.64]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Screw2.geometry}
          material={materials['ddg87-1.001']}
          position={[2.71, -23.92, 65.64]}
        />
        <group position={[-0.04, 0, -0.7]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.DDG87_1.geometry}
            material={materials.ddg87}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.DDG87_2.geometry}
            material={materials['ddg87-1']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.DDG87_3.geometry}
            material={materials.Rail}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Gun.geometry}
          material={materials['ddg87.001']}
          position={[0, -9.43, -48.62]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Radar.geometry}
          material={materials['ddg87.002']}
          position={[0.06, 7.72, -21.85]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Radar2.geometry}
          material={materials['ddg87.003']}
          position={[0.04, 11.71, -19.97]}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/low_poly_flight_two_a.glb')

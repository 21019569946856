import { layer } from 'api/interfaces'
import React from 'react'
import styled, { keyframes } from 'styled-components'
import * as Dialog from '@radix-ui/react-dialog'
import { InfoCircledIcon, Cross2Icon } from '@radix-ui/react-icons'
import { checkLayerName } from './components/dialogComponents/DialogContentSelector'
import DialogContentSelector from './components/dialogComponents/DialogContentSelector'

const overlayShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const contentShow = keyframes`
  from {
    opacity: 0;
    // transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    // transform: translate(-50%, -50%) scale(1);
  }
`

const WrapperDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const InfoIcon = styled(InfoCircledIcon)`
  cursor: pointer;
  width: 20px;
  height: 20px;
  color: white;
  margin-top: 1rem;
  background-color: rgb(0, 0, 0, 0);
  margin-right: 2rem;
`

export const IconButton = styled.button`
  cursor: pointer;
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  top: 10px;
  right: 10px;
  &:hover {
    background-color: white;
    color: rgb(11, 33, 45);
  }
  &:focus {
    box-shadow: 0 0 0 2px white;
  }
`

export const DialogOverlay = styled(Dialog.Overlay)`
  background-color: black;
  position: fixed;
  inset: 0;
  animation: ${overlayShow} 1500ms cubic-bezier(0.16, 1, 0.3, 1);
`

export const DialogContent = styled(Dialog.Content)`
  background-color: rgb(11, 33, 45);
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 50vw;
  max-height: 85vh;
  padding: 25px;
  animation: ${contentShow} 1500ms cubic-bezier(0.16, 1, 0.3, 1);
  &:focus {
    outline: none;
  }
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  height: 0;
  width: 0;
  visibility: hidden;
`

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`

const ToggleLabel = styled.label`
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 20px;
  background: rgba(124, 124, 124, 0.5);
  display: block;
  border-radius: 20px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 4px;
    width: 14px;
    height: 14px;
    background: rgb(11, 33, 45);
    border-radius: 50%;
    transition: 0.3s;
  }

  ${HiddenCheckbox}:checked & {
    background: white;
  }

  ${HiddenCheckbox}:checked + &:after {
    left: calc(100% - 4px);
    transform: translateX(-100%);
    background: white;
  }

  &:active:after {
    width: 24px;
    background: rgb(11, 33, 45);
  }
`

const ToggleText = styled.span<{ isChecked: boolean }>`
  margin-left: 8px;
  color: ${({ isChecked }) => (isChecked ? 'white' : 'rgba(128, 128, 128, 0.8)')};
  transition: color 0.3s;
`
interface LayersLayersWidgetProps {
  layers: layer[]
  inspectionId: string
  setInspectionLayers: (inspectionId: string, layerName: string) => void
  inspectionLayers: Map<string, Map<string, boolean>>
}

function StatsLayersTogglesWidget(props: LayersLayersWidgetProps) {
  const checkLayer = (layer: layer | undefined) => {
    if (!layer) {
      return false
    } else {
      const layerInMap = props.inspectionLayers.get(props.inspectionId)?.get(layer.name)
      if (layerInMap === true) {
        return true
      } else {
        return false
      }
    }
  }

  const handleToggleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    layerName: string
  ) => {
    props.setInspectionLayers(props.inspectionId, layerName)
  }

  const renderLayers = () => {
    // get the last 2 layers from the array, and remove them from the array
    let layer1: layer | undefined
    switch (props.layers.length) {
      case 0:
        return <></>
      default:
        layer1 = props.layers.pop()
        return (
          <WrapperDiv>
            <ToggleWrapper id={`toggle-wrapper-${layer1 ? layer1?.name : ''}`}>
              <HiddenCheckbox
                id={`toggle-${layer1 ? layer1?.name : ''}`}
                {...props}
                checked={checkLayer(layer1)}
                onChange={(e) => handleToggleChange(e, layer1 ? layer1?.name : '')}
              />
              <ToggleLabel
                id={`toggle-label-${layer1 ? layer1?.name : ''}`}
                htmlFor='toggle'
                onClick={() => {
                  props.setInspectionLayers(
                    props.inspectionId,
                    layer1 ? layer1?.name : ''
                  )
                }}
              />
              <ToggleText
                isChecked={checkLayer(layer1)}
                id={`toggle-text-${layer1 ? layer1?.name : ''}`}>
                {layer1 ? layer1?.button_text : ''}
              </ToggleText>
            </ToggleWrapper>
            {checkLayerName(layer1 ? layer1?.name : '') ? (
              <Dialog.Root>
                <Dialog.Trigger
                  asChild
                  id={`dialog-trigger-${layer1 ? layer1?.name : ''}`}>
                  <InfoIcon />
                </Dialog.Trigger>
                <Dialog.Portal id={`dialog-portal-${layer1 ? layer1?.name : ''}`}>
                  <DialogOverlay id={`dialog-overlay-${layer1 ? layer1?.name : ''}`} />
                  <DialogContent id={`dialog-content-${layer1 ? layer1?.name : ''}`}>
                    <DialogContentSelector layerName={layer1 ? layer1.name : ''} />
                    <Dialog.Close
                      asChild
                      id={`dialog-close-${layer1 ? layer1?.name : ''}`}>
                      <IconButton>
                        <Cross2Icon />
                      </IconButton>
                    </Dialog.Close>
                  </DialogContent>
                </Dialog.Portal>
              </Dialog.Root>
            ) : null}
          </WrapperDiv>
        )
    }
  }
  return renderLayers()
}

export default React.memo(StatsLayersTogglesWidget)

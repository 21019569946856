/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from "@react-three/drei"
import * as THREE from 'three'
import { useGraph } from "@react-three/fiber"

interface CutterProps {
  wastage_112: boolean
  wastage_115: boolean
  wastage_118: boolean
  wastage_9: boolean
}
export default function Cutter(props: CutterProps) {
  const gltf = useGLTF("/cutter_with_plates.glb");
  const { nodes, materials } = useGraph(gltf.scene);


  ////////////
    // keeping commmented sections for highlighting plates when hovered over in case we want to use in the future
  ////////////


  // const highlight_mat = new MeshStandardMaterial()
  // highlight_mat.opacity = 0.3
  // highlight_mat.transparent = true
  // highlight_mat.color = new THREE.Color('#FFF000')

  // const [highlightPart, setHighlightPart] = useState(null)

  // const handleMeshHover = (event: any) => {
  //   const mesh = event.object
  //   // setHighlightPart(mesh)
  
  //   mesh.material = new MeshStandardMaterial( {map: heatmapTexture_112})
  //   console.log(mesh.material)
  //   mesh.material.needsUpdate = true
  // }
  
  // const handleMeshUnhover = (event: any) => {
  //   const mesh = event.object
  //   // setHighlightPart(null)
  
  //   const originalMaterial = mesh.material ? materials[mesh.material.name] : null
  //   mesh.material = originalMaterial
  //   mesh.material.needsUpdate = true
  // }

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={ (nodes["Sentinel-class_cutter"] as THREE.Mesh).geometry }
        material={ (nodes["Sentinel-class_cutter"] as THREE.Mesh).material }
      />
      <mesh
        castShadow
        receiveShadow
        geometry={ (nodes["60A61-278"] as THREE.Mesh).geometry.translate(-1, 0, -3) }
        material={ props.wastage_118 ? (nodes["60A61-278"] as THREE.Mesh).material : (nodes["Sentinel-class_cutter"] as THREE.Mesh).material } 
        // onPointerOver={handleMeshHover}
        // onPointerOut={handleMeshUnhover}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={ (nodes["60A61-276"] as THREE.Mesh).geometry.translate(-1, 0, -3) }
        material={ props.wastage_115 ? (nodes["60A61-276"] as THREE.Mesh).material : (nodes["Sentinel-class_cutter"] as THREE.Mesh).material }
      />
      <mesh
        castShadow
        receiveShadow
        geometry={ (nodes["60A61-126"] as THREE.Mesh).geometry.translate(-1, 0, -3) }
        material={ props.wastage_112 ? (nodes["60A61-126"] as THREE.Mesh).material : (nodes["Sentinel-class_cutter"] as THREE.Mesh).material }
        >
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={ (nodes["30A33-012"] as THREE.Mesh).geometry.translate(0, -1, 0) }
        material={ props.wastage_9 ? (nodes["30A33-012"] as THREE.Mesh).material : (nodes["Sentinel-class_cutter"] as THREE.Mesh).material }
        >
      </mesh>
    </group>
  );
}

useGLTF.preload("/cutter_with_plates.glb");
